import axios from 'axios';
import { axiosV2 } from 'libs/common/fetcher';
import { datadogRum } from '@datadog/browser-rum';

export const getShippingIdWithoutWaybill = async (warehouseId: number | string) => {
  try {
    return await axiosV2.get(`/waybills/create/yto`, { params: { warehouseId } });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      datadogRum.addAction(`YTO 송장발행 출고조회 실패 : ${error.response?.data}`);
      return error.response;
    } else {
      datadogRum.addAction(`예상치 못한 오류 발생: ${error}`);
      throw error; // 예상치 못한 오류는 재-throw
    }
  }
};

export type CreateWaybillRequest = {
  warehouseId: number | string;
  shippingIds: number[];
};

export const createWaybill = async (requestBody: CreateWaybillRequest) => {
  try {
    return await axiosV2.post(`/waybills/create/yto`, requestBody);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      datadogRum.addAction(`YTO송장 자동발생 요청 실패 : ${error.response?.data}`);
      return error.response;
    } else {
      datadogRum.addAction(`예상치 못한 오류 발생: ${error}`);
      throw error; // 예상치 못한 오류는 재-throw
    }
  }
};
