import React, { useEffect, useState } from 'react';
import axios, { AxiosError } from 'axios';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { datadogRum } from '@datadog/browser-rum';
import type { ErrorPackingResponse } from 'types/outbound';
import usePopup from 'hooks/usePopup';
import mapPackingErrorToMessage from 'libs/outbound/mapErrorToMessage';
import { PACKING } from 'consts/outbound/messages';
import { isPrintingAtom, printerAtom } from 'store/common/printer.recoil';
import { boxAtom, packingAtom, stepAtom, weightByScaleAtom } from 'store/outbound/packing.recoil';
import { isZpl } from '../../../../../libs/common/isZpl';
import type { PatchPackingRequest } from '../services/packing';
import { fetchWaybill, updatePacking, WaybillRequest } from '../services/packing';
import useApiErrorDialog from '../../hooks/useApiErrorDialog';
import LoadingModal from '../../components/LoadingModal';
import useInitialization from '../hooks/useInitialization';
import { LAST_STEP } from '../index.page';
import { WAYBILL_FORMAT } from 'consts/outbound/waybillFormat';

export const WAYBILL_INFO = 'waybillInfo';

const Printer = () => {
  const [hasFinished, setHasFinished] = useState(false);
  const [printError, setPrintError] = useState('');
  const [image, setImage] = useState('');

  const [isPrinting, setIsPrinting] = useRecoilState(isPrintingAtom);
  const printer = useRecoilValue(printerAtom);
  const packing = useRecoilValue(packingAtom);
  const box = useRecoilValue(boxAtom);
  const weight = useRecoilValue(weightByScaleAtom);
  const setSelectedStep = useSetRecoilState(stepAtom);

  const { showDialog, showAlert, showErrorDialog, showSnackbar } = usePopup();
  const initializeStates = useInitialization();
  const showApiErrorDialog = useApiErrorDialog();

  useEffect(() => {
    if (!packing) {
      return;
    }

    if (printError) {
      setIsPrinting(false);
      showErrorDialog({
        title: '송장출력 오류 안내',
        top: PACKING.printError,
        bottom: PACKING.continuousPrintFailure,
        errorMessage: printError,
        buttons: [
          {
            text: '송장 재출력',
            onClick: () => {
              printImage(
                image,
                () => {
                  datadogRum.addAction(`운송장 재출력 성공: ${packing.id}`);
                  setHasFinished(true);
                },
                () => {
                  datadogRum.addAction(`운송장 재출력 실패: ${packing.id}`);
                  setPrintError('');
                }
              );
            },
            marked: true,
          },
          {
            text: '포장중지',
            onClick: () => {
              changePackingStatusToError('프린터 오류', printError);
              initializeStates();
            },
          },
        ],
      });
      setPrintError('');
    }
  }, [printError, image]);

  const handleUpdateToCompleteStatusError = (error: unknown) => {
    const errorMessage = axios.isAxiosError(error)
      ? (error as AxiosError<ErrorPackingResponse>).response?.data?.errorMessage
      : (error as Error).message;

    showApiErrorDialog(
      errorMessage || '포장 완료 처리 중 오류가 발생했습니다.',
      PACKING.updatePackingFailure,
      () =>
        changePackingStatusToError(
          '포장완료 상태로 변경 실패',
          errorMessage || '포장 완료 처리 중 오류가 발생했습니다.'
        )
    );
  };

  useEffect(() => {
    if (!(packing && box && weight)) {
      return;
    }

    const printInvoice = async () => {
      setIsPrinting(true);

      try {
        const request: WaybillRequest = {
          weight: Number(weight),
          boxCode: box.name,
          boxWidth: box.width,
          boxHeight: box.height,
          boxLength: box.length,
          encodingType: 'ZPL',
        };
        const { base64Image } = await fetchWaybill(packing.id, request);

        printImage(
          base64Image,
          () => {
            datadogRum.addAction(`운송장 출력 완료: ${packing.id}`);
          },
          error => {
            datadogRum.addAction(`Error: 운송장 출력 실패: ${error ?? ''}`, {
              errorMesg: error ?? '',
            });
            setPrintError(error);
            setImage(base64Image);
          }
        );

        const waybillInfo = { packingId: packing.id };
        localStorage.setItem(WAYBILL_INFO, JSON.stringify(waybillInfo));
      } catch (error) {
        const errorMessage = axios.isAxiosError(error)
          ? (error as AxiosError<ErrorPackingResponse>).response?.data?.errorMessage
          : (error as Error).message;
        if (errorMessage?.includes('환불')) {
          showErrorDialog({
            title: '환불이력 출고건',
            errorMessage:
              '환불이력 출고건으로, ‘포장중지‘ 처리 되었습니다.\n' + '담당 매니저를 호출해 주세요.',
            buttons: [
              {
                text: '닫기',
                onClick: () => {
                  changePackingStatusToError('포장오류', '환불이력이 존재함.');
                },
              },
            ],
          });
        } else {
          showErrorDialog({
            top: PACKING.carrierApiError,
            bottom: PACKING.continuousPrintFailure,
            title: '운송장 발행 실패',
            errorMessage: errorMessage ?? '알 수 없는 서버 에러',
            buttons: [
              {
                text: '포장중지',
                onClick: () => {
                  changePackingStatusToError(
                    '운송장 발행 실패',
                    errorMessage ?? '알수 없는 서버 에러'
                  );
                },
                marked: true,
              },
              {
                text: '송장출력 재연동',
                onClick: printInvoice,
              },
            ],
          });
        }
        setIsPrinting(false);
        return; // updatePacking을 실행하지 않고 함수를 종료합니다.
      }

      const updateRequest = {
        id: packing.id,
        status: 'COMPLETED',
      } as PatchPackingRequest;

      try {
        await updatePacking(updateRequest);
        setSelectedStep(LAST_STEP + 1);
        datadogRum.addAction(`포장 완료: packing.id:${packing.id}`, updateRequest);
        setIsPrinting(false);
      } catch (error) {
        datadogRum.addAction(`Error: 포장 완료 실패: packing.id:${packing.id}`, updateRequest);
        handleUpdateToCompleteStatusError(error);
        setIsPrinting(false);
      }
    };

    printInvoice();

    return () => {
      setIsPrinting(false);
    };
  }, [packing, box, weight]);

  const changePackingStatusToError = async (errorCode: string, errorMessage: string) => {
    if (!packing) {
      showAlert({ message: '포장 정보 없음' });
      return;
    }

    try {
      await updatePacking({ id: packing.id, status: 'ERROR', errorCode, errorMessage });
      showSnackbar({ message: PACKING.changeToErrorStatus, severity: 'error' });
      initializeStates();
    } catch (error) {
      const errorMessage = mapPackingErrorToMessage(error);
      showApiErrorDialog(errorMessage, PACKING.changeToErrorStatus);
    }
  };

  const printImage = (image: string, onFinish: () => void, onError: (error: string) => void) => {
    if (!printer) {
      showDialog({ message: PACKING.printNotPrepared, buttons: [] });
      return;
    }
    if (isZpl(image)) {
      printer.send(`${image}`, onFinish, onError);
    } else {
      printer.convertAndSendFile(`data:image/${WAYBILL_FORMAT};base64,${image}`, onFinish, onError);
    }
  };

  return (
    <LoadingModal
      isLoading={isPrinting}
      message={'송장 출력을 준비중입니다\n잠시만 기다려주세요'}
    />
  );
};

export default Printer;
