import { AxiosError } from 'axios';
import { datadogRum } from '@datadog/browser-rum';
import { errorType } from '../../../../../types/common';
import { axiosV2 } from '../../../../../libs/common/fetcher';
import { SearchQuery } from '../../on-site-history/index.page';

export const getPickup = async (orderSeq: string) => {
  try {
    return await axiosV2.get(`/pick-up-onsite?orderSeq=${orderSeq}`);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    datadogRum.addAction(`현장수령 배송정보 조회 실패 : ${error1.response?.data}`);
    return error1.response;
  }
};

export const updatePickup = async (orderSeq: string) => {
  try {
    return await axiosV2.post(`/pick-up-onsite?orderSeq=${orderSeq}`);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    datadogRum.addAction(`현장수령 배송정보 등록 실패 : ${error1.response?.data}`);
    return error1.response;
  }
};

export const patchCompletePickup = async (orderSeq: string) => {
  try {
    return await axiosV2.patch(`/pick-up-onsite/delivered?orderSeq=${orderSeq}`);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    datadogRum.addAction(`현장수령 잔달완료 실패 : ${error1.response?.data}`);
    return error1.response;
  }
};

export const getPickupToPrint = async (orderSeq: string) => {
  try {
    return await axiosV2.get(`/pick-up-onsite/print?orderSeq=${orderSeq}`);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    datadogRum.addAction(`작업지 출력 데이터 호출 실패 : ${error1.response?.data}`);
    return error1.response;
  }
};

export const getPickupToPrintList = async (orderSeq: string) => {
  try {
    return await axiosV2.get(`/pick-up-onsite/print-list?orderSeq=${orderSeq}`);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    datadogRum.addAction(`작업지 출력 데이터 호출 실패 : ${error1.response?.data}`);
    return error1.response;
  }
};

export const getPickupFullHistory = async (param: SearchQuery) => {
  try {
    return await axiosV2.get(`/pick-up-onsite/search`, {
      params: param,
    });
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    datadogRum.addAction(`엑셀 다운로드 데이터 호출 실패 : ${error1.response?.data}`);
    return error1.response;
  }
};
