import * as XLSX from 'xlsx';
import { AxiosError } from 'axios';
import { datadogRum } from '@datadog/browser-rum';
import { errorType } from '../../../../../../../types/common';
import { axiosV2 } from '../../../../../../../libs/common/fetcher';

type LocationInfo = {
  offlineBarcode: string;
  goodsId: number;
  name: string;
  code: string;
  imageUrl: string;
  supplierName: string;
  supplierGoodsCode: string;
  barcode: string;
  locationType: string;
  locationCode: string;
  qty: number;
};

export const getItemLocations = async (goodsIds: (number | string)[]) => {
  try {
    return await axiosV2.get('/receivings/returns/items/locations', {
      params: { goodsIds: goodsIds.join(',') },
    });
  } catch (error) {
    const axiosError = error as AxiosError<errorType, string>;
    datadogRum.addError(`로케이션 정보 조회 실패 : ${axiosError.response?.data}`);
    return axiosError.response;
  }
};

export const createExcelFile = (locationData: LocationInfo[]) => {
  try {
    const translatedData = locationData.map(item => ({
      '공급사': item.supplierName,
      '바코드': item.barcode,
      '물류바코드': item.offlineBarcode,
      '제조사코드': item.supplierGoodsCode,
      '아이템코드': item.code,
      '아이템명': item.name,
      '로케이션구분': item.locationType,
      '로케이션': item.locationCode,
      '로케이션 가용수량': item.qty,
    }));

    const worksheet = XLSX.utils.json_to_sheet(translatedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, '반품아이템 로케이션');

    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `반품아이템_로케이션_${new Date().toISOString().split('T')[0]}.xlsx`;
    link.click();
    return true;
  } catch (error) {
    console.error('엑셀 파일 생성 중 오류 발생:', error);
    return false;
  }
};
