import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Ktown4uLogo from 'components/common/Ktown4uLogo';
import { COLORS } from 'consts/common/colors';
import { signOut } from 'libs/auth';
import { Theme } from '@mui/material/styles';
import { SelectChangeEvent } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Box from '@mui/system/Box';
import { useRecoilState, useRecoilValue } from 'recoil';
import { warehouseAtom, warehouseOptionsAtom } from '../../../store/outbound/warehouse.recoil';
import Stack from '@mui/material/Stack';
import usePopup from '../../../hooks/usePopup';
import { onSiteId } from './menus';

export const warehouseIdKey = 'warehouseId';

const AppBar = () => {
  const [authorized, setAuthorized] = useState<boolean>(false);
  const navigate = useNavigate();
  const accessToken = Cookies.get('accessToken');
  const [warehouse, setWarehouse] = useRecoilState(warehouseAtom);
  const warehousesCodes = useRecoilValue(warehouseOptionsAtom);
  const { showSnackbar } = usePopup();

  const onClickSignInOutButton = () => {
    if (authorized) signOut();
    navigate('/auth/signin');
  };

  useEffect(() => {
    setAuthorized(!!accessToken);
  }, [accessToken, warehouse]);

  const handleChange = (event: SelectChangeEvent) => {
    try {
      localStorage.setItem(warehouseIdKey, event.target.value);
      setWarehouse(localStorage.getItem(warehouseIdKey)?.toString() || '');
      showSnackbar({ message: '창고가 변경되었습니다.', severity: 'success' });
    } catch (error) {
      showSnackbar({ message: '창고 변경에 실패했습니다.', severity: 'error' });
      return;
    }

    if (onSiteId === Number(event.target.value)) {
      navigate('/gms/on-site/pickup');
      return;
    }

    navigate(`/`);
  };

  return (
    <MuiAppBar position="fixed" sx={styles.appBar}>
      <Toolbar sx={styles.toolBar}>
        <Box sx={styles.toolBarLeft}>
          <Ktown4uLogo />
          <Box sx={{ ml: 0.5 }}>
            <Select
              value={warehouse}
              onChange={handleChange}
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                '& .MuiSelect-select': {
                  fontWeight: 'bold',
                },
              }}
            >
              {warehousesCodes?.map(item => (
                <MenuItem key={item.id} value={item.id}>
                  {item.displayName}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
        <Stack flexDirection="row" sx={{ gap: 1 }}>
          {authorized && (
            <Button
              variant="outlined"
              size="small"
              sx={styles.button}
              onClick={() => navigate('/gms/auth/change-password')}
            >
              비밀번호 변경
            </Button>
          )}
          <Button
            variant="outlined"
            size="small"
            sx={styles.button}
            onClick={onClickSignInOutButton}
          >
            {authorized ? '로그아웃' : '로그인'}
          </Button>
        </Stack>
      </Toolbar>
    </MuiAppBar>
  );
};

export default AppBar;

const styles = {
  appBar: {
    zIndex: (theme: Theme) => theme.zIndex.drawer + 1,
    borderBottom: `1px solid ${COLORS.darkLine}`,
    boxShadow: 'none',
  },
  toolBar: {
    pr: 3, // keep right padding when drawer closed
    bgcolor: 'white',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  toolBarLeft: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  button: { color: COLORS.grey, borderColor: COLORS.grey, fontSize: '12px' },
};
