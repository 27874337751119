import { useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import usePopup from '../../../../../hooks/usePopup';
import { errorType } from '../../../../../types/common';
import { COLORS } from '../../../../../consts/common/colors';
import Barcode from '../../../../../components/common/Barcode';
import { getFinalUrl } from '../../../../../consts/outbound/imageUrl';
import ImageZoomModal from '../../../../../components/ImageZoomModal';
import AutoContainImage from '../../../../../components/common/Image/AutoContainImage';
import { afterProgressTitle, inProgressTitle } from '../../../../../styles/timelineItemTitle';
import useBarcode from '../../../outbound/hooks/useBarcode';
import TimelineItem from '../../../outbound/components/TimelineItem';
import BarcodeInput from '../../../outbound/components/BarcodeInput';
import Table, { TableCell, TableRow } from '../../../components/Table';
import ListTable, { Value } from '../../../outbound/components/ListTable';
import { separateArrow } from '../../../outbound/inspection/components/ItemListLine/utils';
import { getPickup, updatePickup } from '../services';
import { Pickup, PICKUP_STATUS, PickupItem } from '../index.page';

const CURRENT_STEP = 0;
const title = '배송ID의 바코드를 스캔해 주세요';

type Props = {
  pickup: Pickup | undefined;
  setPickup: (pickup: Pickup | undefined) => void;
  changeStep: (step: number) => void;
};

const BarcodeScanLine = (props: Props) => {
  const { pickup, setPickup, changeStep } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const { barcode, setBarcode } = useBarcode();
  const { showAlert, showDialog } = usePopup();

  const handleBarcodeInputSubmit = async () => {
    const { valid, message } = validateBarcode(barcode);
    if (!valid) {
      setBarcode('');
      showAlert({ message });
      return;
    }

    setIsLoading(true);

    const response = await getPickup(barcode);
    if (response && response.status === 200) {
      const pickupWithSortedItems = {
        ...response.data,
        items: sortAndMapItems(response.data.items),
      };

      switch (pickupWithSortedItems.status) {
        case PICKUP_STATUS.ready:
          const res = await updatePickup(barcode);
          if (res && res.status === 200) {
            setPickup(pickupWithSortedItems);
            changeStep(CURRENT_STEP + 1);
          } else {
            showAlert({ message: '수령상태 업데이트에 실패했습니다.' });
          }
          break;
        case PICKUP_STATUS.picking:
          showDialog({
            message: `수령상태 '집품중' 입니다.본인수령을 진행하시겠습니까?`,
            buttons: [
              { text: '취소', onClick: () => undefined },
              {
                text: '확인',
                marked: true,
                onClick: () => {
                  setPickup(pickupWithSortedItems);
                  changeStep(CURRENT_STEP + 1);
                },
              },
            ],
          });
          break;
        case PICKUP_STATUS.handedOver:
        case PICKUP_STATUS.pickupCompleted:
          showAlert({ message: '이미 처리된 건 입니다. 다른 배송ID를 스캔해 주세요' });
          break;
      }
    } else {
      showAlert({ message: '배송ID 조회결과가 없습니다. 확인 후 다시 스캔해 주세요' });
    }

    setBarcode('');
    setIsLoading(false);
  };

  return (
    <TimelineItem
      step={0}
      inProgressComponent={
        <>
          <Typography sx={inProgressTitle}>{title}</Typography>
          <BarcodeInput
            value={barcode}
            onChange={e => setBarcode(e.target.value.trim())}
            label="바코드"
            onSubmit={() => handleBarcodeInputSubmit()}
            isLoading={isLoading}
          />
        </>
      }
      afterProgressComponent={
        <Stack>
          <Box>
            <Typography sx={afterProgressTitle}>{title}</Typography>
          </Box>
          <Stack direction="row">
            <Box flex={1}>
              <Table>
                <TableRow height="36px">
                  <TableCell label="고객ID" valueSx={{ width: '100%' }} value={pickup?.userId} />
                </TableRow>
                <TableRow height="36px">
                  <TableCell label="고객명" valueSx={{ width: '100%' }} value={pickup?.userName} />
                </TableRow>
                <TableRow height="36px">
                  <TableCell label="연락처" valueSx={{ width: '100%' }} value={pickup?.userPhone} />
                </TableRow>
                <TableRow height="36px">
                  <TableCell
                    label="수령신청일"
                    valueSx={{ width: '100%' }}
                    value={pickup?.requestedPickUpDate}
                  />
                </TableRow>
              </Table>
            </Box>
            <Box sx={styles.zone}>{pickup?.zone}</Box>
            <Stack sx={styles.barcode}>
              <Barcode value={pickup?.orderSeq ?? ''} height={80} width={1.2} />
              <Typography>{pickup?.orderSeq}</Typography>
            </Stack>
          </Stack>
          <Stack>
            <Typography variant="h6" sx={styles.subTitleStyle}>
              [주문내역]
            </Typography>
            <ListTable
              rows={pickup?.items ?? []}
              columns={gridColumns(imageUrl, setImageUrl)}
              total={5}
              sx={{ maxHeight: '400px' }}
            />
          </Stack>
        </Stack>
      }
    />
  );
};

export default BarcodeScanLine;

const styles = {
  zone: {
    width: 146,
    height: 146,
    m: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 1,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: COLORS.lightLine,
    fontSize: 100,
    fontWeight: 'bold',
  },
  barcode: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 300,
    flex: 1,
  },
  subTitleStyle: {
    fontWeight: 700,
    color: COLORS.grey,
    margin: '24px 0 12px 0',
  },
};

const gridColumns = (imageUrl: string, setImageUrl: (url: string) => void) => {
  return [
    { headerName: 'NO', field: 'id', width: '10%' },
    { headerName: '바코드', field: 'barcode', width: '10%' },
    { headerName: '상품코드', field: 'goodsCode', width: '10%' },
    {
      headerName: '이미지',
      field: 'imageUrl',
      width: '10%',
      renderCell: (url: Value) => {
        const finalUrl = getFinalUrl(url as string);
        return (
          <>
            <Box
              sx={{ display: 'flex', alignItems: 'center', padding: 0.5, justifyContent: 'center' }}
              onClick={() => setImageUrl(finalUrl.toString())}
            >
              <AutoContainImage src={finalUrl.toString()} imageContainerSize={50} />
            </Box>
            <ImageZoomModal imageUrl={imageUrl} onClose={() => setImageUrl('')} />
          </>
        );
      },
    },
    {
      headerName: '상품명',
      field: 'goodsName',
      width: '50%',
      renderCell: (itemName: Value) => {
        if (!itemName) return '';

        const [item, remark] = (itemName as string).split(separateArrow);
        return (
          <p style={{ textAlign: 'start' }}>
            <span>{item}</span>
            <span style={{ color: 'red' }}>{remark ? separateArrow + remark : ''}</span>
          </p>
        );
      },
    },
    { headerName: '수량', field: 'qty', width: '10%' },
  ];
};

const validateBarcode = (barcode: string) => {
  if (barcode.trim() === '') {
    return { valid: false, message: '바코드를 입력해 주세요.' };
  }

  if (!isAlphanumeric(barcode)) {
    return {
      valid: false,
      message: `키보드가 영문모드인지 확인 후 다시 스킨해 주세요. 바코드번호: ${barcode}`,
    };
  }

  return { valid: true, message: '' };
};

const isAlphanumeric = (input: string): boolean => {
  return /^[a-zA-Z0-9]+$/.test(input);
};

export const sortAndMapItems = (items: PickupItem[]) => {
  return items
    .sort((a: PickupItem, b: PickupItem) => (a.goodsName > b.goodsName ? 1 : -1))
    .map((item: PickupItem, index: number) => {
      item.id = (index + 1).toString();
      item.barcode = item.offlineBarcode || item.barcode;
      return item;
    });
};
