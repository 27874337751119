import React, { ChangeEvent, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { KeyedMutator } from 'swr';
import { SelectChangeEvent } from '@mui/material';
import Box from '@mui/system/Box';
import Typography from '@mui/material/Typography';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import usePopup from '../../../../../hooks/usePopup';
import FormModal from '../../../../../components/common/FormModal';
import { FONT_SIZE } from '../../../../../consts/common/typography';
import CONVEYOR_PATH from '../../../../../consts/info/conveyorPath';
import refCodeOptionsAtom from '../../../../../store/outbound/refCode.recoil';
import { warehouseOptionsAtom } from '../../../../../store/outbound/warehouse.recoil';
import convertWarehouseFullCode from '../../../../../libs/warehouse/convertWarehouseFullCode';
import SaveButtonGroup from '../../../components/SaveButtonGroup';
import Table, { TableCell, TableRow } from '../../../components/Table2';
import SelectBase from '../../devices/components/SelectBase';
import { ConveyorCondition, getConveyor, updateConveyorCondition } from '../services';
import { ConveyorResponse } from '../index.page';

const modalWidth = 480;
const modalFontSize = FONT_SIZE.medium;

const itemCountThresholdOptions = [
  { value: 1, displayName: '1' },
  { value: 2, displayName: '2' },
  { value: 3, displayName: '3' },
  { value: 4, displayName: '4' },
  { value: 5, displayName: '5 ' },
  { value: 6, displayName: '6' },
  { value: 7, displayName: '7' },
  { value: 8, displayName: '8' },
  { value: 9, displayName: '9' },
  { value: 10, displayName: '10' },
  { value: 500, displayName: '500' },
];

type Info = {
  id: number;
  warehouseId: number;
  basePath: string;
  conveyorPath: string;
};

type Props = {
  conveyorId: number;
  isModalOpen: boolean;
  onClose: () => void;
  onMutate: KeyedMutator<ConveyorResponse>;
};

const initInfo = {
  id: -1,
  warehouseId: -1,
  basePath: '',
  conveyorPath: '',
};

const initCondition = {
  itemCount: 1,
  comparisonType: '',
  minRealWeight: 0,
  maxRealWeight: 0,
  minLength: 0,
  maxLength: 0,
  minWidth: 0,
  maxWidth: 0,
  minHeight: 0,
  maxHeight: 0,
  minVolumeWeight: 0,
  maxVolumeWeight: 0,
};

const ConveyorConditionFormModal = ({ conveyorId, isModalOpen, onClose, onMutate }: Props) => {
  const warehouseOption = useRecoilValue(warehouseOptionsAtom);
  const refCodeOptions = useRecoilValue(refCodeOptionsAtom);
  const conveyorPathOptions = refCodeOptions?.conveyorPath || [];

  const { showErrorDialog, showSnackbar } = usePopup();
  const [info, setInfo] = useState<Info>(initInfo);
  const [condition, setCondition] = useState<ConveyorCondition>(initCondition);
  const [isLoading, setIsLoading] = useState(false);

  const isAirManual = info?.basePath === 'AIR_MANUAL';

  useEffect(() => {
    if (conveyorId < 0) {
      return;
    }

    const getConveyorInfo = async () => {
      const response = await getConveyor(conveyorId);
      if (response?.status === 200) {
        setInfo(makeInfo(response.data));
        setCondition(makeDefaultCondition(response.data));
      } else {
        showErrorDialog({
          title: '포장경로 정보 조회 실패',
          errorMessage: response?.data.errorMessage,
          buttons: [{ text: '확인' }],
        });
      }
    };

    getConveyorInfo();
  }, [conveyorId]);

  const handleChange = (event: ChangeEvent<HTMLInputElement> | SelectChangeEvent) => {
    const { name, value } = event.target;

    if (name === 'comparisonType') {
      setCondition(condition => ({ ...condition, ['comparisonType']: value }));
      return;
    }

    const numericValue = Number(value.replace(/[^0-9]/g, ''));
    if (numericValue <= 0) {
      showSnackbar({ message: '1 이상으로 입력해 주세요.', severity: 'error' });
      setCondition(condition => ({ ...condition, [name]: '' }));
      return;
    }

    setCondition(condition => ({ ...condition, [name]: numericValue }));
  };

  const handleUpdate = async () => {
    if (!validateInputValues(condition)) return;

    setIsLoading(true);

    const response = await updateConveyorCondition(info.id, condition);
    if (response?.status === 200) {
      showSnackbar({ message: '권장포장경로 조건이 수정 완료되었습니다.', severity: 'success' });
      setInfo(initInfo);
      setCondition(initCondition);
      await onMutate();
      onClose();
    } else {
      showErrorDialog({
        title: '권장포장경로 조건 수정 실패',
        errorMessage: response?.data.errorMessage,
        buttons: [{ text: '확인' }],
      });
    }

    setIsLoading(false);
  };

  const validateInputValues = (condition: ConveyorCondition) => {
    if (
      !condition.maxRealWeight ||
      !condition.minRealWeight ||
      !condition.maxVolumeWeight ||
      !condition.minVolumeWeight ||
      !condition.minWidth ||
      !condition.maxWidth ||
      !condition.minLength ||
      !condition.maxLength ||
      !condition.minHeight ||
      !condition.maxHeight
    ) {
      showSnackbar({ message: '필수입력정보를 입력해 주세요.', severity: 'error' });
      return false;
    }

    if (condition.minRealWeight > condition.maxRealWeight) {
      showSnackbar({ message: '실제중량무게 최소값이 최대값보다 큽니다.', severity: 'error' });
      return false;
    } else if (condition.minVolumeWeight > condition.maxVolumeWeight) {
      showSnackbar({ message: '실제부피무게 최소값이 최대값보다 큽니다.', severity: 'error' });
      return false;
    } else if (condition.minWidth > condition.maxWidth) {
      showSnackbar({ message: '가로 최소값이 최대값보다 큽니다.', severity: 'error' });
      return false;
    } else if (condition.minLength > condition.maxLength) {
      showSnackbar({ message: '세로 최소값이 최대값보다 큽니다.', severity: 'error' });
      return false;
    } else if (condition.minHeight > condition.maxHeight) {
      showSnackbar({ message: '높이 최소값이 최대값보다 큽니다.', severity: 'error' });
      return false;
    }

    return true;
  };

  return (
    <FormModal open={isModalOpen} title={'권장포장경로 조건 수정'} onClose={onClose}>
      <Box width={modalWidth}>
        <Box>
          <Typography sx={styles.modalSubTitle}>포장경로 정보</Typography>
        </Box>
        <Table>
          <TableRow sx={styles.rowHeight}>
            <TableCell label={'창고'} labelSx={styles.label}>
              <Typography fontSize={modalFontSize} fontWeight={'bold'}>
                {convertWarehouseFullCode(Number(info.warehouseId), warehouseOption)}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow sx={styles.rowHeight}>
            <TableCell label={'권장포장경로'} labelSx={styles.label}>
              <Typography fontSize={modalFontSize} fontWeight={'bold'}>
                {CONVEYOR_PATH[info.basePath as keyof typeof CONVEYOR_PATH]}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow sx={styles.rowHeight}>
            <TableCell label={'권장작업공간'} labelSx={styles.label}>
              <Typography fontSize={modalFontSize} fontWeight={'bold'}>
                {conveyorPathOptions?.find(v => v.value === info.basePath)?.displayName}
              </Typography>
            </TableCell>
          </TableRow>
        </Table>
        <>
          <Box sx={{ mt: 2.6 }}>
            <Typography sx={styles.modalSubTitle}>카톤랩/제네시스 정보</Typography>
          </Box>
          {isAirManual ? (
            <>
              <Table>
                <TableRow sx={styles.rowHeight}>
                  <TableCell label={'아이템 개수'} labelSx={styles.label}>
                    <Typography>전체</Typography>
                  </TableCell>
                </TableRow>
                <TableRow sx={styles.rowHeight}>
                  <TableCell label={'실제중량무게(g)'} labelSx={styles.label} required>
                    <Typography>전체</Typography>
                  </TableCell>
                </TableRow>
                <TableRow sx={styles.rowHeight}>
                  <TableCell label={'실제부피무게(g)'} labelSx={styles.label} required>
                    <Typography>전체</Typography>
                  </TableCell>
                </TableRow>
                <TableRow sx={styles.rowHeight}>
                  <TableCell label={'가로(mm)'} labelSx={styles.label} required>
                    <Typography>전체</Typography>
                  </TableCell>
                </TableRow>
                <TableRow sx={styles.rowHeight}>
                  <TableCell label={'세로(mm)'} labelSx={styles.label} required>
                    <Typography>전체</Typography>
                  </TableCell>
                </TableRow>
                <TableRow sx={styles.rowHeight}>
                  <TableCell label={'높이(mm)'} labelSx={styles.label} required>
                    <Typography>전체</Typography>
                  </TableCell>
                </TableRow>
              </Table>
              <Box sx={styles.closeButtonContainer}>
                <Button variant="outlined" onClick={onClose}>
                  닫기
                </Button>
              </Box>
            </>
          ) : (
            <>
              <Table>
                <TableRow sx={styles.rowHeight}>
                  <TableCell label={'아이템 개수'} labelSx={styles.label}>
                    <Box sx={styles.tableContent}>
                      <Box>
                        <SelectBase
                          name={'itemCount'}
                          sx={styles.itemCountSelect}
                          options={itemCountThresholdOptions}
                          selected={condition.itemCount.toString()}
                          onChange={handleChange}
                        />
                      </Box>
                      <RadioGroup
                        name={'comparisonType'}
                        value={condition.comparisonType}
                        onChange={handleChange}
                        sx={styles.tableRadioGroup}
                      >
                        <FormControlLabel
                          value="LTE"
                          control={<Radio size={'small'} />}
                          label="이하"
                        />
                        <FormControlLabel
                          value="GT"
                          control={<Radio size={'small'} />}
                          label="초과"
                        />
                      </RadioGroup>
                    </Box>
                  </TableCell>
                </TableRow>
                <TableRow sx={styles.rowHeight}>
                  <TableCell label={'실제중량무게(g)'} labelSx={styles.label} required>
                    <TableContent
                      name={'RealWeight'}
                      min={condition.minRealWeight}
                      max={condition.maxRealWeight}
                      onChange={handleChange}
                    />
                  </TableCell>
                </TableRow>
                <TableRow sx={styles.rowHeight}>
                  <TableCell label={'실제부피무게(g)'} labelSx={styles.label} required>
                    <TableContent
                      name={'VolumeWeight'}
                      min={condition.minVolumeWeight}
                      max={condition.maxVolumeWeight}
                      onChange={handleChange}
                    />
                  </TableCell>
                </TableRow>
                <TableRow sx={styles.rowHeight}>
                  <TableCell label={'가로(mm)'} labelSx={styles.label} required>
                    <TableContent
                      name={'Width'}
                      min={condition.minWidth}
                      max={condition.maxWidth}
                      onChange={handleChange}
                    />
                  </TableCell>
                </TableRow>
                <TableRow sx={styles.rowHeight}>
                  <TableCell label={'세로(mm)'} labelSx={styles.label} required>
                    <TableContent
                      name={'Length'}
                      min={condition.minLength}
                      max={condition.maxLength}
                      onChange={handleChange}
                    />
                  </TableCell>
                </TableRow>
                <TableRow sx={styles.rowHeight}>
                  <TableCell label={'높이(mm)'} labelSx={styles.label} required>
                    <TableContent
                      name={'Height'}
                      min={condition.minHeight}
                      max={condition.maxHeight}
                      onChange={handleChange}
                    />
                  </TableCell>
                </TableRow>
              </Table>
              <SaveButtonGroup
                onSaveClick={handleUpdate}
                onCloseClick={onClose}
                isLoading={isLoading}
              />
            </>
          )}
        </>
      </Box>
    </FormModal>
  );
};

export default ConveyorConditionFormModal;

const makeInfo = (data: Info) => {
  return {
    id: data.id,
    warehouseId: data.warehouseId,
    basePath: data.basePath,
    conveyorPath: data.conveyorPath,
  };
};

type OriginConveyorCondition = {
  itemCountComparison: {
    threshold: number;
    comparisonType: string;
  };
  weightComparison: {
    gramWeight: number;
    comparisonType: string;
    minWeight: number;
    maxWeight: number;
  };
  volumeWeightComparison: {
    minVolumeWeight: number;
    maxVolumeWeight: number;
  };
  widthComparison: {
    minWidth: number;
    maxWidth: number;
  };
  lengthComparison: {
    minLength: number;
    maxLength: number;
  };
  heightComparison: {
    minHeight: number;
    maxHeight: number;
  };
};

const makeDefaultCondition = (data: OriginConveyorCondition) => {
  return {
    itemCount: data.itemCountComparison.threshold,
    comparisonType: data.itemCountComparison.comparisonType,
    minRealWeight: data.weightComparison.minWeight,
    maxRealWeight: data.weightComparison.maxWeight,
    minVolumeWeight: data.volumeWeightComparison.minVolumeWeight,
    maxVolumeWeight: data.volumeWeightComparison.maxVolumeWeight,
    minWidth: data.widthComparison.minWidth,
    maxWidth: data.widthComparison.maxWidth,
    minLength: data.lengthComparison.minLength,
    maxLength: data.lengthComparison.maxLength,
    minHeight: data.heightComparison.minHeight,
    maxHeight: data.heightComparison.maxHeight,
  };
};

type TableContentProps = {
  name: string;
  min: number;
  max: number;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

const TableContent = ({ name, min, max, onChange }: TableContentProps) => {
  return (
    <Box sx={styles.tableContent}>
      <TextField
        name={'min' + name}
        variant="outlined"
        sx={styles.textField}
        value={min && min.toLocaleString()}
        onChange={onChange}
      />
      <Typography fontSize={13} mr={2.6}>
        이상 ~
      </Typography>
      <TextField
        name={'max' + name}
        variant="outlined"
        sx={styles.textField}
        value={max && max.toLocaleString()}
        onChange={onChange}
      />
      <Typography fontSize={13}>미만</Typography>
    </Box>
  );
};

const styles = {
  select: { width: '344px', ml: -1.5 },
  modalSubTitle: { fontSize: '20px', mb: 1.5 },
  rowHeight: { height: '36px' },
  label: { width: '128px', fontSize: modalFontSize },
  itemCountSelect: { width: 120, ml: -1.5 },
  textField: {
    ml: -1.5,
    mr: 0.6,
    width: 120,
    height: 30,
    '& .MuiInputBase-formControl': {
      height: 30,
      width: 120,
    },
  },
  tableContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  tableRadioGroup: {
    display: 'flex',
    flexDirection: 'row',
    ml: 2,
    flex: 1,
    '& .MuiFormControlLabel-label': {
      fontSize: '13px',
    },
  },
  closeButtonContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
  },
};
