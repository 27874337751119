import React, { useEffect, useRef } from 'react';
import JsBarcode from 'jsbarcode';

type Props = {
  value: string;
  height: number;
  width?: number;
};

const Barcode = (props: Props) => {
  const { value, height, width = 1 } = props;
  const svgRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    if (svgRef.current) {
      JsBarcode(svgRef.current, value, {
        format: 'CODE128',
        width,
        displayValue: false,
        margin: 0,
        height,
      });
    }
  }, [value, height]);

  return <svg ref={svgRef} />;
};

export default Barcode;
