import React, { useState } from 'react';
import { KeyedMutator } from 'swr';
import Box from '@mui/system/Box';
import Typography from '@mui/material/Typography';
import SaveButtonGroup from './SaveButtonGroup';
import type { Carrier, CarrierResponse } from '../index.page';
import TableWithCustomCell from './TableWithCustomCell';

import generateCarrierRows from '../utils/generateCarrierRows';
import { makeRequestBodyForUpdate } from '../utils';
import { updateCarrier } from '../services/carriers';
import { datadogRum } from '@datadog/browser-rum';
import usePopup from '../../../../../hooks/usePopup';

const modalWidth = 480;

type ModalForm =
  | 'id'
  | 'name'
  | 'displayOrder'
  | 'chuteNumber'
  | 'minWeight'
  | 'maxWeight'
  | 'volumetricDivisor';

export type CarrierForm = Pick<Carrier, ModalForm>;

export const defaultCarrierForm: CarrierForm = {
  id: 0,
  name: '',
  displayOrder: 0,
  chuteNumber: '',
  minWeight: 0,
  maxWeight: 0,
  volumetricDivisor: 0,
};

type CarriersFormProps = {
  selectedCarrier: CarrierForm | undefined;
  mutate: KeyedMutator<CarrierResponse>;
  onClose: () => void;
};
const CarriersForm = (props: CarriersFormProps) => {
  const { selectedCarrier = defaultCarrierForm, mutate, onClose } = props;
  const [carrier, setCarrier] = useState<CarrierForm>(selectedCarrier);
  const carrierRows = generateCarrierRows(carrier);
  const { showErrorDialog, showSnackbar } = usePopup();

  const handleUpdate = async () => {
    const requestBody = makeRequestBodyForUpdate(carrier);
    const response = await updateCarrier(carrier.id, requestBody);
    if (response?.status === 200) {
      showSnackbar({ message: '배송방식 정보가 수정 완료되었습니다.', severity: 'success' });
      await mutate();
      onClose();
      datadogRum.addAction(`배송방식 수정 성공: ${carrier}`);
    } else {
      showErrorDialog({
        title: '배송방식 수정 실패',
        errorMessage: response?.data.errorMessage,
        buttons: [{ text: '확인' }],
      });
    }
  };

  return (
    <Box width={modalWidth}>
      <Box>
        <Typography sx={styles.modalSubTitle}>배송방식 기본정보</Typography>
      </Box>
      <TableWithCustomCell rows={carrierRows.basics} setCarrier={setCarrier} />
      <Box sx={{ mt: 4 }}>
        <Typography sx={styles.modalSubTitle}>배송방식 상세정보</Typography>
      </Box>
      <TableWithCustomCell rows={carrierRows.details} setCarrier={setCarrier} />
      <SaveButtonGroup onSaveClick={handleUpdate} onCloseClick={onClose} />
    </Box>
  );
};

export default CarriersForm;

const styles = {
  modalSubTitle: { fontSize: '20px', mb: 1.5 },
};
