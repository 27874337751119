import { useEffect } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import Box from '@mui/system/Box';
import Typography from '@mui/material/Typography';
import pageTitle from '../../../../styles/pageTitle';
import borderedBox from '../../../../styles/borderedBox';
import { barcodeAtom } from '../../../../store/common/barcode.recoil';
import { packingAtom, stepAtom } from '../../../../store/outbound/packing.recoil';
import Page from '../../../../components/common/Layout/Page';
import ToteCodeLine from './components/ToteCodeLine';
import BoxLine from './components/BoxLine';
import WeightLine from './components/WeightLine';
import CompletionNotice from './components/CompletionNotice';
import Timeline from '../components/Timeline';
import { updatePacking } from './services/packing';
import FullScreenButton from '../components/FullScreenButton';
import { LAST_STEP } from '../packing/index.page';

const PackingInStore = () => {
  const step = useRecoilValue(stepAtom);
  const packing = useRecoilValue(packingAtom);
  const resetPacking = useResetRecoilState(packingAtom);
  const resetBarcode = useResetRecoilState(barcodeAtom);
  const resetStep = useResetRecoilState(stepAtom);

  useEffect(() => {
    if (!packing) {
      return;
    }

    const handleWindowChange = () => {
      packing.status === 'PROCESSING' && updatePacking({ id: packing.id, status: 'READY' });
    };

    window.addEventListener('beforeunload', handleWindowChange);

    return () => {
      window.removeEventListener('beforeunload', handleWindowChange);
    };
  }, [packing]);

  useEffect(() => {
    resetPacking();
    resetBarcode();
    resetStep();
  }, [resetPacking, resetBarcode, resetStep]);

  return (
    <>
      <Page>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h2" sx={pageTitle}>
            매장 대체출고포장 관리
          </Typography>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <FullScreenButton />
          </Box>
        </Box>
        <Box
          sx={{
            ...borderedBox,
            flex: 1,
            height: '90vh',
            padding: '32px',
          }}
        >
          <Timeline currentStep={step} sx={{ padding: 0, margin: 0 }}>
            <ToteCodeLine />
            <BoxLine />
            <WeightLine />
          </Timeline>
          {step === LAST_STEP + 1 && <CompletionNotice />}
        </Box>
      </Page>
    </>
  );
};

export default PackingInStore;
