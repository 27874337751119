import Box from '@mui/material/Box';
import { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Outlet, useLoaderData } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { drawerOpenAtom, shouldMountAppBarAtom, shouldMountDrawerAtom } from 'store';
import devOnlyFlag from '../../../libs/common/devOnlyFlag';
import authAtom, { Auth } from '../../../store/auth.recoil';
import {
  allWarehouseOptionsAtom,
  warehouseAtom,
  warehouseOptionsAtom,
} from '../../../store/outbound/warehouse.recoil';
import AppBar, { warehouseIdKey } from './AppBar';
import Drawer from './Drawer';
import { reloadOutletAtom } from '../../../store/common/menu.recoil';

const Layout = () => {
  const [drawerOpen, setDrawerOpen] = useRecoilState(drawerOpenAtom);
  const shouldMountAppBar = useRecoilValue(shouldMountAppBarAtom);
  const shouldMountDrawer = useRecoilValue(shouldMountDrawerAtom);
  const setAuth = useSetRecoilState(authAtom);
  const setUserWarehouse = useSetRecoilState(warehouseOptionsAtom);
  const allWarehouseOptions = useRecoilValue(allWarehouseOptionsAtom);
  const [warehouse, setWarehouse] = useRecoilState(warehouseAtom);
  const reloadOutlet = useRecoilValue(reloadOutletAtom);

  const authData = useLoaderData() as Auth;
  useEffect(() => {
    if (authData) {
      const {
        loginId,
        username,
        primaryWarehouseId,
        availableWarehouseIds,
        changePasswordRequired,
      } = authData;
      setAuth({
        loginId,
        username,
        primaryWarehouseId,
        availableWarehouseIds,
        changePasswordRequired,
      });

      setWarehouse(
        warehouse === ''
          ? primaryWarehouseId.toString()
          : localStorage.getItem(warehouseIdKey)?.toString() ?? warehouse
      );
      const warehouses = allWarehouseOptions.filter(v => availableWarehouseIds.includes(v.id));
      setUserWarehouse(warehouses);
    }
  }, [authData, allWarehouseOptions]);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>케타포 GMS</title>
        </Helmet>
      </HelmetProvider>
      <Box sx={styles.container}>
        {shouldMountAppBar && <AppBar />}
        {shouldMountDrawer && (
          <Drawer
            open={drawerOpen}
            onOpen={() => setDrawerOpen(true)}
            onClose={() => setDrawerOpen(false)}
          />
        )}
        <Box key={reloadOutlet} component="main" sx={styles.outlet}>
          <Outlet />
        </Box>
      </Box>
    </>
  );
};

export default Layout;

const styles = {
  container: {
    display: 'flex',
    height: '100vh',
    overflow: 'hidden',
    backgroundColor: '#f3f3f7',
  },
  outlet: {
    flex: 1,
    overflow: 'auto',
    backgroundColor: '#f3f3f7',
  },
};
