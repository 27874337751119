import { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { useReactToPrint } from 'react-to-print';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { GridRowParams, GridSelectionModel } from '@mui/x-data-grid-pro';
import usePopup from '../../../../hooks/usePopup';
import pageTitle from '../../../../styles/pageTitle';
import fetcher from '../../../../libs/common/fetcher';
import Page from '../../../../components/common/Layout/Page';
import convertUser from '../../../../libs/common/convertUser';
import Filter, { Form } from '../../../../components/common/Filter';
import { listBoardTheme } from '../../../../styles/customedMuiTheme';
import { ROWS_PER_PAGE_OPTIONS } from '../../../../consts/common/dataGrid';
import TightDataGridPro from '../../../../components/common/TightDataGridPro';
import { DEFAULT_PAGE, DEFAULT_SIZE } from '../../../../consts/common/pageAndSize';
import useConditionalSWR from '../../components/useConditionalSwr';
import Receipt from '../components/Receipt';
import gridColDef from './gridColDef';
import TopBoard from './components/TopBoard';
import { Pickup, PICKUP_STATUS } from '../on-site/index.page';
import { getPickupFullHistory, getPickupToPrintList } from '../on-site/services';
import { errorType } from '../../../../types/common';
import { createExcelFile } from './service/exceldownload';
export type PickupHistory = {
  id: number;
  rowIndex: number;
  orderNo: number;
  orderSeq: string;
  receivingConfirmedDate: string;
  requestedPickUpDate: string;
  status: string;
  updateDate: string;
  workerId: string;
  workerName: string;
  zone: string;
};

type PickupHistoryProgress = {
  total: number;
  ready: number;
  picking: number;
  handOver: number;
  pickupComplete: number;
};

export type PickupHistoryResponse = {
  content: PickupHistory[];
  totalElements: number;
  size: number;
  number: number;
};

export type SearchQuery = {
  page?: number;
  size?: number;
};

const defaultOption = { displayName: '전체', value: 'ALL' };
const requestedPickUpDateOptions = [
  {
    displayName: '2025-02-15',
    value: '2025-02-15',
  },
  {
    displayName: '2025-02-16',
    value: '2025-02-16',
  },
];
export const statusOptions = [
  { displayName: '준비', value: 'READY' },
  { displayName: '집품중', value: 'PICKING' },
  { displayName: '전달완료', value: 'HANDED_OVER' },
  { displayName: '수령완료', value: 'PICKUP_COMPLETE' },
];
const searchOptions = [
  { displayName: '고객명', field: 'userName' },
  { displayName: '고객ID', field: 'userId' },
];

const gridTemplateColumns = [
  '80px',
  'minmax(45px, 2fr)',
  'minmax(45px, 2fr)',
  '60px',
  'minmax(55px, 1fr)',
  'minmax(55px, 1fr)',
  '60px',
  'minmax(55px, 2fr)',
  '0px',
  '0px',
  '0px',
  '0px',
].reduce((prevValue, labelWidth) => prevValue + labelWidth + ' ', '');

const PickupOnSiteHistory = () => {
  const contentRef = useRef(null);
  const statusRef = useRef<{ initialValues: () => void }>(null);

  const [selectedItemIds, setSelectedItemIds] = useState<string[]>([]);
  const [selectedPickups, setSelectedPickups] = useState<Pickup[]>([]);
  const { showAlert } = usePopup();

  const reactToPrintFn = useReactToPrint({ contentRef, onAfterPrint: () => window.close() });
  useEffect(() => {
    selectedPickups.length > 0 && reactToPrintFn();
  }, [selectedPickups]);

  const [hasSearched, setHasSearched] = useState(false);
  const [queryParams, setQueryParams] = useState<SearchQuery>({
    page: DEFAULT_PAGE,
    size: DEFAULT_SIZE,
  });

  const { data, mutate, isValidating } = useConditionalSWR<PickupHistoryResponse>(
    [`/pick-up-onsite/search`, { ...queryParams, sort: 'id,desc' }],
    fetcher,
    hasSearched
  );

  const { data: progressData } = useConditionalSWR<PickupHistoryProgress>(
    [`/pick-up-onsite/progress`],
    fetcher,
    hasSearched
  );

  const topBoardData = [
    { title: '총건수', value: progressData?.total ?? 0 },
    { title: '준비', value: progressData?.ready ?? 0 },
    { title: '집품중', value: progressData?.picking ?? 0 },
    { title: '전달완료', value: progressData?.handOver ?? 0 },
    { title: '수령확인완료', value: progressData?.pickupComplete ?? 0 },
  ];

  const makeRowIndex = (data: PickupHistoryResponse) => {
    const { content, totalElements, size, number } = data;

    return content.map((item: PickupHistory, index: number) => ({
      ...item,
      id: item.orderSeq,
      rowIndex: totalElements - size * number - index,
      worker: convertUser(item.workerName, item.workerId),
    }));
  };

  const handleSearchClick = async (form: Form) => {
    const updatedForm = _.omitBy(form, o => o === defaultOption.value);

    setQueryParams(({ size }) => ({ ...updatedForm, size, page: DEFAULT_PAGE }));
    !hasSearched && setHasSearched(true);
    await mutate();
  };

  const handleInitClick = async () => {
    statusRef.current?.initialValues();

    setQueryParams({
      page: DEFAULT_PAGE,
      size: DEFAULT_SIZE,
    });
  };

  const handleSelectionModelChange = (ids: GridSelectionModel) => {
    setSelectedItemIds(ids as string[]);
  };

  const handlePrint = async () => {
    if (selectedItemIds.length === 0) {
      showAlert({ message: '배송건을 선택해 주세요' });
      return;
    }

    const response = await getPickupToPrintList(selectedItemIds.join(','));
    if (response && response.status == 200) {
      setSelectedPickups(response?.data);
    } else {
      const error = response?.data as errorType;
      showAlert({ message: error?.errorMessage });
    }
  };

  const handleExcelDownload = async () => {
    const filteredQueryParams = { ...queryParams };
    delete filteredQueryParams.page;
    delete filteredQueryParams.size;

    const response = await getPickupFullHistory(filteredQueryParams);
    if (response && response.status !== 200) {
      const error = response?.data as errorType;
      showAlert({ message: error?.errorMessage });
      return;
    }

    const isExportSuccess = createExcelFile(response?.data.content);
    if (!isExportSuccess) {
      showAlert({ message: '엑셀 파일 생성 중 오류가 발생했습니다.' });
    }
  };

  return (
    <Page>
      <Typography variant="h2" sx={pageTitle}>
        본인수령 이력 조회
      </Typography>
      <TopBoard data={topBoardData} />
      <Filter gridTemplateColumns={gridTemplateColumns}>
        <Filter.Select
          label="수령신청일자"
          field="requestedPickUpDate"
          options={[defaultOption, ...requestedPickUpDateOptions]}
          labelGridColumn="1/2"
          selectGridColumn="2/3"
        />
        <Filter.MultipleSelectPlaceholder
          ref={statusRef}
          label="수령상태"
          field="statusList"
          options={[defaultOption, ...statusOptions]}
          labelGridColumn="4/5"
          selectGridColumn="5/7"
        />
        <Filter.VerticalSearchWithSelect
          label="배송ID"
          labelGridRow="1/2"
          labelGridColumn="7/8"
          selectOptions={[{ displayName: '', field: 'orderSeq' }]}
          defaultSelectValue="orderSeqs"
          selectGridRow="1/1"
          selectGridColumn="7/8"
          textAreaGridRow="1/3"
          textAreaGridColumn="8/13"
          minRows={3}
          maxRows={3}
          showSelect={false}
        />
        <Filter.SearchWithSelect
          label="검색어"
          searchOptions={searchOptions}
          labelGridColumn="1/2"
          selectGridColumn="2/3"
          inputGridColumn="3/4"
        />
        <Filter.Search
          label="연락처"
          field="userPhone"
          labelGridColumn="4/5"
          inputGridColumn="5/7"
        />
        <Divider sx={styles.divider} />
        <Filter.DefaultButtonGroup
          sx={styles.filterDefaultButtonGroup}
          gridColumn="1/13"
          onInitClick={handleInitClick}
          onLookupClick={handleSearchClick}
        />
      </Filter>
      <Stack spacing={1} sx={listBoardTheme.container}>
        <Box sx={listBoardTheme.header}>
          <Typography variant="h6" sx={{ color: 'text.primary' }}>
            본인수령 출고건 목록
          </Typography>
          <Box>
            <Button sx={listBoardTheme.headerButton} variant="contained" onClick={handlePrint}>
              주문서 출력
            </Button>
            <Button
              sx={listBoardTheme.headerButton}
              variant="outlined"
              onClick={handleExcelDownload}
            >
              엑셀다운로드
            </Button>
          </Box>
        </Box>
        <TightDataGridPro
          rows={hasSearched && data?.content ? makeRowIndex(data) : []}
          columns={gridColDef(statusOptions)}
          paginationMode="server"
          rowCount={hasSearched ? data?.totalElements ?? 0 : 0}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          onPageChange={page => setQueryParams(params => ({ ...params, page }))}
          pageSize={queryParams.size ?? DEFAULT_SIZE}
          onPageSizeChange={size => setQueryParams(params => ({ ...params, size }))}
          loading={isValidating}
          hasSearched={hasSearched}
          checkboxSelection
          disableSelectionOnClick
          selectionModel={selectedItemIds}
          onSelectionModelChange={handleSelectionModelChange}
          isRowSelectable={(params: GridRowParams<Pickup>) =>
            params.row.status === PICKUP_STATUS.ready || params.row.status === PICKUP_STATUS.picking
          }
        />
      </Stack>
      <Box sx={styles.receiptContainer} ref={contentRef}>
        {selectedPickups?.map((item, index) => (
          <Receipt key={index} pickup={item} />
        ))}
      </Box>
    </Page>
  );
};

export default PickupOnSiteHistory;

const styles = {
  filterDefaultButtonGroup: {
    justifySelf: 'center',
  },
  gridBorderTopTitle: {
    color: 'text.primary',
  },
  divider: {
    gridRow: 3,
    gridColumn: '1/-1',
    pb: 1,
    mb: 1,
  },
  tightDataGrid: {
    bgColor: 'white',
    elevation: 6,
  },
  receiptContainer: {
    position: 'fixed',
    left: 0,
    top: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: -1,
    backgroundColor: 'white',
  },
};
