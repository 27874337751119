import dayjs from 'dayjs';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { GridCellEditCommitParams } from '@mui/x-data-grid-pro';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import Page from '../../../../../components/common/Layout/Page';
import TightDataGridSum from '../../../../../components/common/TightDataGridSum';
import { getFinalUrl } from '../../../../../consts/outbound/imageUrl';
import usePopup from '../../../../../hooks/usePopup';
import refCodeOptionsAtom from '../../../../../store/outbound/refCode.recoil';
import { listBoardTheme } from '../../../../../styles/customedMuiTheme';
import pageTitle from '../../../../../styles/pageTitle';
import TableHorizontal, { TableCell, TableRow } from '../../../components/TableHorizontal';
import gridColDef from './gridColDef';
import {
  getReceiptsInspectDetails,
  inspectReceiving,
  receivingItemInspectionRequests,
} from './services';
import ImageZoomModal from '../../../../../components/ImageZoomModal';

type InboundReceivingInspectionDetailContent = {
  receivingItemId: number | string;
  barcode: string;
  offlineBarcode: string;
  itemCode: string;
  imageUrl: string;
  itemName: string;
  expirationDate: string;
  receivedQty: number;
  inspectedQty: number;
  totalInspectedCost: number;
  cost: number;
  width: number | string;
  height: number | string;
  length: number | string;
  weight: number | string;
  itemId: number | string;
};

type InboundReceivingInspectionDetail = {
  receivingId: number;
  receivingItemResponses: InboundReceivingInspectionDetailContent[];
  receivedDate: string;
  receivingName: string;
  supplierName: string;
  status: string;
};

const ReceivingInspectDetails = () => {
  const {
    state: { receivingId },
  } = useLocation();

  const [data, setData] = useState<InboundReceivingInspectionDetail>();
  const [imageUrl, setImageUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { showErrorDialog, showSnackbar } = usePopup();
  const refCodeOptions = useRecoilValue(refCodeOptionsAtom);
  const receivingStatusOptions = refCodeOptions?.receivingStatus || [];

  useEffect(() => {
    const fetchData = async () => {
      const response = await getReceiptsInspectDetails(receivingId);
      if (response?.status === 200) {
        setData(makeDataWithRowIndex(response.data));
      } else {
        showErrorDialog({
          title: '입고검수 상세 조회 실패',
          errorMessage: response?.data?.errorMessage,
          buttons: [{ text: '확인' }],
        });
      }
    };

    fetchData();
  }, []);

  const topBoards = [
    { label: '입고ID', value: data?.receivingName },
    { label: '공급업체', value: data?.supplierName },
    { label: '입고일', value: data?.receivedDate },
    {
      label: '상태',
      value: data?.status
        ? receivingStatusOptions.find(item => item.value === data.status)?.displayName
        : '',
    },
  ];

  const handleGoConfirmListBack = () => {
    window.history.back();
  };

  const handleCellEditCommit = (params: GridCellEditCommitParams) => {
    data && setData(updateDataByInput(data, params));
  };

  const updateDataByInput = (
    data: InboundReceivingInspectionDetail,
    params: GridCellEditCommitParams
  ): InboundReceivingInspectionDetail => {
    const { field, value, id } = params;

    const updatedItem = data.receivingItemResponses.find(item => item.receivingItemId === id);
    if (!updatedItem) return data;

    const updatedFields = {
      ...updatedItem,
      [field]: field === 'expirationDate' ? value : Number(value),
      'totalInspectedCost':
        field === 'inspectedQty'
          ? updatedItem.cost * Number(value)
          : updatedItem.totalInspectedCost,
    };

    return {
      ...data,
      receivingItemResponses: data.receivingItemResponses.map(item =>
        item.receivingItemId === id ? updatedFields : item
      ),
    };
  };

  const handleInspected = () => {
    if (!data) return;
    const inspectedItems = data.receivingItemResponses.filter(item => item.inspectedQty > 0);
    if (inspectedItems.length === 0) {
      showErrorDialog({
        title: '검수 수량을 입력해주세요.',
        errorMessage: '검수 수량을 입력하지 않은 품목이 있습니다.',
        buttons: [{ text: '확인' }],
      });
      return;
    }

    const isAllInspected = inspectedItems.every(item => item.inspectedQty === item.receivedQty);
    if (!isAllInspected) {
      showErrorDialog({
        title: '검수 수량을 확인해주세요.',
        errorMessage: '검수 수량이 입고 수량과 일치하지 않습니다.',
        buttons: [{ text: '확인' }],
      });
      return;
    }

    // 무게가 입력되지 않은 경우
    const isAllWeight = inspectedItems.every(item => item.weight > 0);
    if (!isAllWeight) {
      showErrorDialog({
        title: '무게를 입력해주세요.',
        errorMessage: '무게를 입력하지 않은 품목이 있습니다.',
        buttons: [{ text: '확인' }],
      });
      return;
    }

    // 가로 세로 높이가 입력되지 않은 경우
    const isAllDimension = inspectedItems.every(
      item => item.width > 0 && item.height > 0 && item.length > 0
    );
    if (!isAllDimension) {
      showErrorDialog({
        title: '가로, 세로, 높이를 입력해주세요.',
        errorMessage: '가로, 세로, 높이를 입력하지 않은 품목이 있습니다.',
        buttons: [{ text: '확인' }],
      });
      return;
    }

    handleInspectReceiving();
  };

  const handleInspectReceiving = async () => {
    setIsLoading(true);
    const requestBody = data?.receivingItemResponses.map(item => ({
      receivingItemId: item.receivingItemId,
      goodsId: item.itemId,
      width: item.width,
      height: item.height,
      length: item.length,
      weight: item.weight,
      quantity: item.receivedQty,
      receivingLpnRequests: [
        {
          quantity: item.inspectedQty,
          expirationDate: dayjs(item.expirationDate).format('YYYY-MM-DD'),
          status: 'GENERAL',
          type: 'GENERAL',
        },
      ],
    }));
    const res =
      data &&
      (await inspectReceiving(data?.receivingId, requestBody as receivingItemInspectionRequests[]));
    if (res?.status === 200) {
      showSnackbar({ message: '검수가 완료되었습니다.', severity: 'success' });
      window.history.back();
    } else {
      showErrorDialog({
        title: '입고검수 실패',
        errorMessage: res?.data?.errorMessage,
        buttons: [{ text: '확인' }],
      });
    }
    setIsLoading(false);
  };

  return (
    <Page>
      <Typography variant="h2" sx={pageTitle}>
        입고검수 상세
      </Typography>
      <Stack spacing={1} sx={listBoardTheme.container}>
        <TableHorizontal>
          <TableRow>
            {topBoards.map(board => (
              <TableCell key={board.label} label={board.label} value={board.value} />
            ))}
          </TableRow>
        </TableHorizontal>
        <Box sx={listBoardTheme.header}>
          <Typography variant="h6" sx={{ color: 'text.primary' }}>
            검수 아이템 목록
          </Typography>
        </Box>
        <TightDataGridSum
          getRowId={row => row.id}
          rows={addSumRowToItems(data?.receivingItemResponses) ?? []}
          columns={gridColDef}
          hideFooter
          disableSelectionOnClick
          onCellEditCommit={handleCellEditCommit}
          onCellClick={({ field, row }) => {
            if (field === 'imageUrl') setImageUrl(getFinalUrl(row.imageUrl));
          }}
          hasSearched={true}
        />
        <Stack flexDirection="row" gap={1} justifyContent="flex-end">
          <Button variant="outlined" onClick={handleGoConfirmListBack} sx={{ width: 110 }}>
            목록
          </Button>
          <LoadingButton
            variant="contained"
            onClick={handleInspected}
            loading={isLoading}
            disabled={data?.status == 'INSPECTED'}
            sx={{ width: 110 }}
          >
            검수완료
          </LoadingButton>
        </Stack>
        <ImageZoomModal imageUrl={imageUrl} onClose={() => setImageUrl('')} />
      </Stack>
    </Page>
  );
};

export default ReceivingInspectDetails;

const makeDataWithRowIndex = (data: InboundReceivingInspectionDetail) => {
  return {
    ...data,
    id: data.receivingId,
    receivingItemResponses: data?.receivingItemResponses.map(
      (item: InboundReceivingInspectionDetailContent, index: number) => ({
        ...item,
        rowIndex: index + 1,
        id: item.receivingItemId,
        inspectedQty: item.receivedQty ?? 0, // 디폴트 검수수량 설정
        totalInspectedCost: item.cost * (item.inspectedQty ?? item.receivedQty),
        expirationDate: item.expirationDate ?? '9999-12-31', // 디폴트 유효기한 설정
      })
    ),
  };
};

const addSumRowToItems = (items: InboundReceivingInspectionDetailContent[] | undefined) => {
  if (items === undefined) return [];

  const summery = {
    rowIndex: 'total',
    id: '',
    receivingItemId: '',
    barcode: '',
    offlineBarcode: '',
    itemCode: '',
    imageUrl: '',
    itemName: '',
    expirationDate: '',
    receivedQty: items.reduce((acc, cur) => acc + cur.receivedQty, 0) ?? 0,
    inspectedQty: items.reduce((acc, cur) => acc + cur.inspectedQty, 0) ?? 0,
    totalInspectedCost: items.reduce((acc, cur) => acc + cur.totalInspectedCost, 0) ?? 0,
    cost: items.reduce((acc, cur) => acc + cur.cost, 0) ?? 0,
    width: '',
    height: '',
    length: '',
    weight: '',
    itemId: '',
  } as InboundReceivingInspectionDetailContent;

  return items.concat(summery);
};
