import React from 'react';
import { useParams } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import useSWR from 'swr';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import fetcher from 'libs/common/fetcher';
import { COLORS } from 'consts/common/colors';
import PopupTemplate from '../../../components/PopupTemplate';
import ListTable from '../../../components/ListTable';

type History = {
  historyType: string;
  historyDescription: string;
  itemCode: string;
  createdAt: string;
  barcode: string;
  deliverySeq: string;
};

const InspectionErrors = () => {
  const { id } = useParams();
  const { data, error } = useSWR<History[]>(`/inspections/${id}/histories`, fetcher);
  const wrongType = '오집품';
  const histories = data
    ?.map((history, i) => ({
      ...history,
      order: i + 1,
      id: history.createdAt,
      createdAt: new Date(history.createdAt).toLocaleString('sv-SE'),
      historyType: history.historyType,
      historyDescription:
        history.historyType === wrongType
          ? '[' + history.barcode + '] ' + history.historyDescription
          : '[' + history.itemCode + '] ' + history.historyDescription,
      deliverySeq: history.deliverySeq,
    }))
    .sort((a, b) => b.order - a.order);

  return (
    <>
      {error ? (
        '오류 내역을 불러오지 못했습니다'
      ) : (
        <>
          <HelmetProvider>
            <Helmet>
              <title>케타포 GMS - 검수 오류내역 조회</title>
            </Helmet>
          </HelmetProvider>
          <PopupTemplate
            title="검수 오류내역 조회"
            info={
              <>
                <Typography>배송ID</Typography>
                <Typography sx={{ fontWeight: 700, color: COLORS.ktown4u }}>
                  {histories?.[0]?.deliverySeq}
                </Typography>
              </>
            }
            subTitle="검수 오류내역 목록"
            main={<ListTable hideFooter={true} columns={columns} rows={histories ?? []} />}
            footer={
              <Button variant="outlined" sx={{ width: '75px' }} onClick={() => window.close()}>
                닫기
              </Button>
            }
          />
        </>
      )}
    </>
  );
};

export default InspectionErrors;

const columns = [
  {
    headerName: 'NO',
    field: 'order',
    width: 20,
  },
  {
    headerName: '오류 케이스',
    field: 'historyType',
    width: 60,
  },
  {
    headerName: '오류내역 기록',
    field: 'historyDescription',
    width: 160,
  },
  {
    headerName: '오류 발생 일시',
    field: 'createdAt',
    width: 80,
  },
];
