import React, { ChangeEvent, useState } from 'react';
import { KeyedMutator } from 'swr';
import Box from '@mui/system/Box';
import usePopup from 'hooks/usePopup';
import SaveButtonGroup from '../../../components/SaveButtonGroup';
import { TableCell, TableRow } from '../../../components/Table';
import FormControl from '@mui/material/FormControl';
import { PickingGroup } from '../index.page';
import { fetchPickingGroup } from '../services';
import _ from 'lodash';
import { Select, SelectChangeEvent } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { COLORS } from 'consts/common/colors';
import TextField from '@mui/material/TextField';
import devOnlyFlag from 'libs/common/devOnlyFlag';

const modalWidth = 750;
type RequestQuery = {
  warehouseId: number | string;
  bulkCount?: number;
  singleCount?: number;
  carrierIds?: string;
  shippingBasedAtFrom?: string;
  shippingBasedAtTo?: string;
  shippingOrderCreatedAtFrom?: string;
  shippingOrderCreatedAtTo?: string;
  keyIds?: number[];
  hasTube?: boolean;
  shippingOrderType: string;
  salesTypeByShops?: string;
  reason?: string;
  itemCodes?: string;
  itemBarcodes?: string;
  deliverySeqs?: string;
  shippingSerialNumbers?: string;
};

type PickingGroupFormProps = {
  request: RequestQuery;
  mutate: KeyedMutator<PickingGroup>;
  onClose: () => void;
  keyIds?: number[];
};

const RequestBodyForCreateKeys = [
  'warehouseId',
  'bulkCount',
  'singleCount',
  'carrierIds',
  'shippingBasedAtFrom',
  'shippingBasedAtTo',
  'shippingOrderCreatedAtFrom',
  'shippingOrderCreatedAtTo',
  'keyIds',
  'hasTube',
  'shippingOrderType',
  'salesTypeByShops',
  'reason',
  'itemCodes',
  'itemBarcodes',
  'deliverySeqs',
  'shippingSerialNumbers',
] as const;

export const makeRequestBodyForCreate = (request: RequestQuery) => {
  const requestBody = {
    ...request,
    carrierIds: request.carrierIds?.split(', ').map(id => Number(id)),
    salesTypeByShops: processInputAsString(request.salesTypeByShops ?? ''),
    itemCodes: processInputAsString(request.itemCodes ?? ''),
    itemBarcodes: processInputAsString(request.itemBarcodes ?? ''),
    deliverySeqs: processInputAsString(request.deliverySeqs ?? ''),
    shippingSerialNumbers: processInputAsString(request.shippingSerialNumbers ?? ''),
  };

  return _.pick(requestBody, RequestBodyForCreateKeys);
};

const processInputAsString = (input: string): string[] => {
  return input
    .split(',')
    .map(item => item.trim()) // 공백 제거
    .filter(item => item !== ''); // 빈값 제거
};

const defaultBulkCount = 100;
const defaultSingleCount = 16;

const PickingGroupForm = (props: PickingGroupFormProps) => {
  const { showSnackbar, showErrorDialog } = usePopup();
  const { onClose, mutate, request, keyIds } = props;
  const [countRequest, setCountRequest] = useState({
    ...request,
    bulkCount: defaultBulkCount,
    singleCount: defaultSingleCount,
    reason: '',
    keyIds,
  });
  const [bulk, setBulk] = useState(defaultBulkCount.toString());
  const [singleCount, setSingleCount] = useState(defaultSingleCount.toString());
  const requestBody = makeRequestBodyForCreate(countRequest);
  const [isLoading, setIsLoading] = useState(false);
  const handleCreate = async () => {
    setIsLoading(true);
    const response = await fetchPickingGroup(requestBody);
    if (response?.status === 200) {
      showSnackbar({
        message: '집품그룹이 생성 완료되었습니다.',
        severity: 'success',
      });
    } else {
      showErrorDialog({
        title: '집품그룹 생성 실패',
        errorMessage: response?.data?.errorMessage,
        buttons: [{ text: '확인' }],
      });
    }
    setIsLoading(false);
    await mutate();
    onClose();
  };

  const handleChange = (event: SelectChangeEvent) => {
    const bulkCount = parseInt(event.target.value);
    setCountRequest({
      ...countRequest,
      'bulkCount': bulkCount,
    });
    setBulk(event.target.value);
  };

  const handleSingleCountChange = (event: SelectChangeEvent) => {
    const singleCount = parseInt(event.target.value);
    setCountRequest({
      ...countRequest,
      'singleCount': singleCount,
    });
    setSingleCount(event.target.value);
  };

  const handleReasonChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCountRequest({
      ...countRequest,
      'reason': event.target.value,
    });
  };

  return (
    <Box width={modalWidth}>
      <Box sx={styles.container}>
        <TableRow>
          <TableCell
            label={'대량집품 기준'}
            value={
              <FormControl fullWidth>
                <Box>
                  동일포장건수{'  '}
                  <Select value={bulk} onChange={handleChange} sx={styles.select}>
                    {_.range(10, 30, 5).map(num => (
                      <MenuItem value={num} key={num}>
                        {num}
                      </MenuItem>
                    ))}
                    {_.range(30, 110, 10).map(num => (
                      <MenuItem value={num} key={num}>
                        {num}
                      </MenuItem>
                    ))}
                    <MenuItem value={300} key={300}>
                      {300}
                    </MenuItem>
                    <MenuItem value={4000} key={4000}>
                      {4000}
                    </MenuItem>
                    {devOnlyFlag() && (
                      <MenuItem value={4100} key={4100}>
                        {4100}
                      </MenuItem>
                    )}
                  </Select>
                  건 이상
                </Box>
              </FormControl>
            }
          />
        </TableRow>
        <TableRow>
          <TableCell
            label={'1주문다집품 기준'}
            value={
              <FormControl fullWidth>
                <Box>
                  무게 20,000g 이상 또는 다음 배송방식의 출고건
                  <br />
                  배송방식 : DHL Bulk, EMS Bulk, Shipping(海运), FedEx, 퀵(파랑새), 퀵(대형화물)
                </Box>
              </FormControl>
            }
          />
        </TableRow>
        <TableRow>
          <TableCell
            label={'개별집품 출고건수'}
            value={
              <FormControl>
                <Box>
                  집품그룹 당 출고건수{'  '}
                  <Select value={singleCount} onChange={handleSingleCountChange} sx={styles.select}>
                    {_.range(20, 0, -1).map(num => (
                      <MenuItem value={num} key={num}>
                        {num}
                      </MenuItem>
                    ))}
                  </Select>
                  건 기준
                </Box>
              </FormControl>
            }
          />
        </TableRow>
        <TableRow>
          <TableCell
            label={'비고'}
            value={
              <TextField
                variant="outlined"
                size="small"
                style={{ width: '550px' }}
                onChange={handleReasonChange}
              />
            }
          />
        </TableRow>
      </Box>
      <SaveButtonGroup
        onSaveClick={handleCreate}
        onCloseClick={onClose}
        isLoading={isLoading}
        saveButtonName={'집품그룹 생성'}
      />
    </Box>
  );
};

export default PickingGroupForm;

const styles = {
  container: {
    border: `1px solid ${COLORS.darkLine}`,
  },
  select: {
    height: '32px',
  },
};
