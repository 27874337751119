import type { GridColDef } from '@mui/x-data-grid-pro';

type Option = {
  displayName: string;
  value: string;
};

const gridColumns = (statusOptions: Option[]) => {
  const columns: GridColDef[] = [
    {
      field: 'rowIndex',
      headerName: 'NO',
      minWidth: 20,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'requestedPickUpDate',
      headerName: '수령신청일자',
      minWidth: 120,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'status',
      headerName: '수령상태',
      minWidth: 80,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) =>
        statusOptions.find(option => option.value === value)?.displayName,
    },
    {
      field: 'orderSeq',
      headerName: '배송ID',
      minWidth: 230,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'orderNo',
      headerName: '배송번호',
      minWidth: 80,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'zone',
      headerName: '수령zone',
      minWidth: 40,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'worker',
      headerName: '작업자',
      minWidth: 160,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'updateDate',
      headerName: '작업일시',
      minWidth: 160,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'receivingConfirmedDate',
      headerName: '수령확인완료일시',
      minWidth: 160,
      headerAlign: 'center',
      align: 'center',
    },
  ];
  return columns;
};

export default gridColumns;
