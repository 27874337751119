import Box from '@mui/system/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import usePopup from '../../../../../hooks/usePopup';
import { errorType } from '../../../../../types/common';
import { COLORS } from '../../../../../consts/common/colors';
import CompletionCard from '../../../outbound/components/CompletionCard';
import { PICKUP_STATUS } from '../index.page';
import { getPickup } from '../services';

type Props = {
  orderSeq: string;
  changeStep: (step: number) => void;
};

const CompleteNotice = (props: Props) => {
  const { orderSeq, changeStep } = props;
  const { showAlert } = usePopup();

  const handleCheckPickup = async () => {
    const response = await getPickup(orderSeq);
    if (response?.status === 200) {
      if (response.data.status === PICKUP_STATUS.handedOver) {
        showAlert({ message: '아직 수령확인 버튼클릭 전 입니다' });
      } else if (response.data.status === PICKUP_STATUS.pickupCompleted) {
        showAlert({ message: '수령확인 처리가 완료되었습니다' });
      }
    } else {
      const error = response?.data as errorType;
      showAlert({ message: error?.errorMessage });
    }
  };

  return (
    <CompletionCard
      content={
        <Stack textAlign="center">
          <Typography sx={{ fontSize: '20px', color: COLORS.veryDarkGrey }}>
            전달완료 처리 되었습니다.
          </Typography>
          <Box sx={{ display: 'flex', gap: '24px' }}>
            <Typography sx={{ fontSize: '28px', fontWeight: 700, color: COLORS.veryDarkGrey }}>
              고객분께 <span style={{ color: COLORS.ktown4u }}>'수령확인'</span> 버튼클릭을 요청해
              주세요.
            </Typography>
          </Box>
        </Stack>
      }
      actions={
        <Stack direction={'row'} gap={2} justifyContent={'center'} m={1}>
          <Button variant="outlined" onClick={handleCheckPickup}>
            수령확인 조회
          </Button>
          <Button variant="contained" sx={{ fontWeight: 700 }} onClick={() => changeStep(0)}>
            다음 본인수령 시작
          </Button>
        </Stack>
      }
    />
  );
};

export default CompleteNotice;
