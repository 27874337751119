import { useState } from 'react';
import Typography from '@mui/material/Typography';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { PACKING } from '../../../../../consts/outbound/messages';
import getWeightByVolume from '../../../../../libs/outbound/getWeightByVolume';
import { boxAtom, packingAtom, stepAtom } from '../../../../../store/outbound/packing.recoil';
import {
  afterProgressTitle,
  beforeProgressTitle,
  inProgressTitle,
} from '../../../../../styles/timelineItemTitle';
import { Box } from '../../../../../types/outbound';
import usePopup from '../../../../../hooks/usePopup';
import Table, { TableCell, TableRow } from '../../../components/Table';
import TimelineItem from '../../components/TimelineItem';
import WeightInput from '../../components/WeightInput';
import LoadingModal from '../../components/LoadingModal';
import { fetchWaybill, WaybillRequest } from '../services/packing';
import PrintWaybill from './PrintWaybill';
import { LAST_STEP } from '../../packing/index.page';

export const WEIGHT_STEP = 2;
const WeightLine = () => {
  const box = useRecoilValue(boxAtom);
  const packing = useRecoilValue(packingAtom);
  const volumetricDivisor = packing?.volumetricDivisor ?? 5000;
  const weightByVolume = box ? getWeightByVolume(box, volumetricDivisor) : undefined;
  const setStep = useSetRecoilState(stepAtom);
  const { showSnackbar } = usePopup();

  const [input, setInput] = useState('');
  const [base64Image, setBase64Image] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (
    box: Box | undefined,
    packingId: number | undefined,
    input: string
  ) => {
    if (!box || !packingId) {
      return;
    }

    const isNumber = !isNaN(Number(input));
    setIsLoading(true);

    if (isNumber) {
      const request: WaybillRequest = {
        weight: Number(input),
        boxCode: box.name,
        boxWidth: box.width,
        boxHeight: box.height,
        boxLength: box.length,
        encodingType: 'BASE64',
      };
      const { base64Image } = await fetchWaybill(packingId, request);
      setBase64Image(base64Image);
    } else {
      showSnackbar({ message: PACKING.invalidWeight, severity: 'warning' });
    }
  };

  const handlePrintCompleted = () => {
    setStep(LAST_STEP + 1);
    setInput('');
    setBase64Image('');
    setIsLoading(false);
  };

  return (
    <>
      <TimelineItem
        isLast
        step={WEIGHT_STEP}
        beforeProgressComponent={
          <Typography sx={beforeProgressTitle}>{PACKING.weightForStore}</Typography>
        }
        inProgressComponent={
          <>
            <Typography sx={inProgressTitle}>{PACKING.weightForStore}</Typography>
            {
              <Table>
                <TableRow>
                  <TableCell
                    sx={styles.tableCell}
                    label="포장실제중량무게(g)"
                    value={
                      <WeightInput
                        value={input}
                        onChange={e => setInput(e.target.value)}
                        onSubmit={async () => {
                          await handleSubmit(box, packing?.id, input);
                        }}
                        inputSxProps={styles.tableCell}
                      />
                    }
                    valueProps={valueCellStyle}
                  />
                  <TableCell
                    sx={styles.tableCell}
                    label="포장실제부피무게(g)"
                    value={Number(weightByVolume).toLocaleString()}
                  />
                </TableRow>
              </Table>
            }
          </>
        }
        afterProgressComponent={
          <>
            <Typography sx={afterProgressTitle}>{PACKING.weightForStore}</Typography>
            <Table>
              <TableRow>
                <TableCell
                  sx={styles.tableCell}
                  label="포장실제중량무게(g)"
                  value={Number(input).toLocaleString()}
                />
                <TableCell
                  sx={styles.tableCell}
                  label="포장실제부피무게(g)"
                  value={Number(weightByVolume).toLocaleString()}
                />
              </TableRow>
            </Table>
          </>
        }
      />
      {base64Image && <PrintWaybill base64Image={base64Image} onCompleted={handlePrintCompleted} />}
      <LoadingModal
        isLoading={isLoading}
        message={'송장 출력을 준비중입니다\n잠시만 기다려주세요'}
      />
    </>
  );
};

export default WeightLine;

const styles = {
  tableCell: { fontSize: '16px' },
};

const valueCellStyle = {
  sx: { display: 'flex', alignItems: 'center', flex: 1, gap: 0.5, px: 1 },
};
