import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import _ from 'lodash';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Page from 'components/common/Layout/Page';
import Filter, { Form } from 'components/common/Filter';
import TightDataGridPro from 'components/common/TightDataGridPro';
import { ROWS_PER_PAGE_OPTIONS } from 'consts/common/dataGrid';
import fetcher from 'libs/common/fetcher';
import pageTitle from 'styles/pageTitle';
import { listBoardTheme } from 'styles/customedMuiTheme';
import warehouseAtom, { warehouseOptionsAtom } from '../../../../store/outbound/warehouse.recoil';
import { Options } from '../../../../types/form';
import { DEFAULT_PAGE, DEFAULT_SIZE } from '../../../../consts/common/pageAndSize';
import convertUser from '../../../../libs/common/convertUser';
import gridColDef from './gridColDef';
import InfoListSection from '../../../../components/common/typography/InfoListSection';
import convertWarehouseFullCode from '../../../../libs/warehouse/convertWarehouseFullCode';
import { stocktakingOptionsAtom } from '../../../../store/outbound/stocktaking.recoil';
import LoadingButton from '@mui/lab/LoadingButton';
import { generateExcelDownload } from './services/excelDownload';
import usePopup from '../../../../hooks/usePopup';
import useConditionalSWR from '../../components/useConditionalSwr';

type stocktakingLocationItem = {
  id: number;
  warehouseId: number;
  locationCode: string;
  barcode: string;
  offlineBarcode: string;
  code: string;
  name: string;
  snapshotQty: number;
  verifiedQty: number;
  expirationDate: string;
  updatedUsername: string;
  updatedLoginId: string;
  updatedAt: string;
};

type StocktakingLocationItemResponse = {
  content: stocktakingLocationItem[];
  totalElements: number;
  size: number;
  number: number;
};

type StocktakingResponse = {
  id: number;
  warehouseId: number;
  name: string;
  serialNumber: string;
};

type SearchQuery = {
  page?: number;
  size?: number;
  warehouseId?: number;
  stocktakingId?: string;
};

const defaultOption = { displayName: '전체', value: 'ALL' };
const emptyOption = { displayName: '선택', value: '-' };

const gridTemplateColumns = [
  '50px',
  'minmax(45px, 0.6fr)',
  'minmax(45px, 0.6fr)',
  '50px',
  'minmax(45px, 0.6fr)',
  'minmax(55px, 1.3fr)',
  '50px',
  'minmax(55px, 1fr)',
  'minmax(55px, 1fr)',
  '50px',
  'minmax(55px, 0.6fr)',
  'minmax(55px, 0.6fr)',
].reduce((prevValue, labelWidth) => prevValue + labelWidth + ' ', '');

const searchKeywordOptions: Options = [
  {
    displayName: '로케이션',
    field: 'locationCode',
  },
];

const StocktakingLocationItems = () => {
  const globalWarehouse = useRecoilValue(warehouseAtom);
  const warehouseOption = useRecoilValue(warehouseOptionsAtom);
  const stocktakingOption = useRecoilValue(stocktakingOptionsAtom);
  const [hasSearched, setHasSearched] = useState(false);
  const [queryParams, setQueryParams] = useState<SearchQuery>({
    page: DEFAULT_PAGE,
    size: DEFAULT_SIZE,
    warehouseId: Number(globalWarehouse),
  });
  const [isExcelDownload, setIsExcelDownload] = useState(false);
  const { showErrorDialog, showSnackbar } = usePopup();

  const { data, isValidating, mutate, error } = useConditionalSWR<StocktakingLocationItemResponse>(
    [`/inventories/stocktakings/locations/items/search`, { ...queryParams, sort: 'id,desc' }],
    fetcher,
    hasSearched
  );

  const { data: stocktakingData } = useConditionalSWR<StocktakingResponse>(
    [`/inventories/stocktakings/${queryParams.stocktakingId}`],
    fetcher,
    hasSearched
  );

  const filteredStocktakingOptions = stocktakingOption.filter(
    option => option.warehouseId === Number(globalWarehouse)
  );

  const warehouseName = convertWarehouseFullCode(
    stocktakingData?.warehouseId as number,
    warehouseOption
  );

  const infoList = [
    { name: '창고', value: warehouseName[0] },
    { name: '재고조사명', value: stocktakingData?.name },
    { name: '재고조사ID', value: stocktakingData?.serialNumber },
  ];

  const makeRowIndex = (data: StocktakingLocationItemResponse) => {
    const { content, totalElements, size, number } = data;

    return content.map((item: stocktakingLocationItem, index: number) => ({
      ...item,
      id: index, // 단순 조회용이므로 id 커스텀함
      rowIndex: totalElements - size * number - index,
      differenceQty: item.verifiedQty - item.snapshotQty,
      updatedUser: convertUser(item.updatedUsername, item.updatedLoginId),
    }));
  };

  const handleSearchClick = async (form: Form) => {
    const updatedForm = _.omitBy(form, o => o === defaultOption.value);
    if (updatedForm.stocktakingId === '-') {
      showSnackbar({ message: '재고조사 항목을 선택해주세요.', severity: 'warning' });
      return;
    }

    setQueryParams(({ size }) => ({ ...updatedForm, size, page: DEFAULT_PAGE }));
    !hasSearched && setHasSearched(true);
    await mutate();
  };

  const handleInitClick = async () => {
    setQueryParams({
      page: DEFAULT_PAGE,
      size: DEFAULT_SIZE,
      warehouseId: Number(globalWarehouse),
    });
  };

  const handleExcelDownload = async () => {
    setIsExcelDownload(true);
    const response = await generateExcelDownload(queryParams);
    if (response?.status === 200) {
      showSnackbar({ message: '엑셀 다운로드가 완료되었습니다.', severity: 'success' });
      setIsExcelDownload(false);
    } else {
      showErrorDialog({
        title: '엑셀 다운로드 실패',
        errorMessage: response?.errorMessage,
        buttons: [{ text: '확인' }],
      });
    }
    setIsExcelDownload(false);
  };

  return (
    <Page>
      <Typography variant="h2" sx={pageTitle}>
        재고조사 재고정보 조회
      </Typography>
      <Filter gridTemplateColumns={gridTemplateColumns}>
        <Filter.Select
          label="재고조사"
          field="stocktakingId"
          options={[...filteredStocktakingOptions, emptyOption]}
          labelGridColumn="1/2"
          selectGridColumn="2/5"
          defaultValue={emptyOption.value}
        />
        <Filter.SearchWithSelect
          label="검색어"
          searchOptions={searchKeywordOptions}
          labelGridColumn="5/6"
          selectGridColumn="6/7"
          inputGridColumn="7/10"
        />
        <Divider sx={styles.divider} />
        <Filter.DefaultButtonGroup
          gridColumn="1/-1"
          gridRow={5}
          onInitClick={handleInitClick}
          onLookupClick={handleSearchClick}
          sx={{ display: 'flex', justifyContent: 'center' }}
        />
      </Filter>
      <Stack spacing={1} sx={listBoardTheme.container}>
        <Box>
          <InfoListSection infoList={infoList} hideValue={!data || error} />
        </Box>
        <Box sx={listBoardTheme.header}>
          <Typography variant="h6" sx={{ color: 'text.primary' }}>
            로케이션별 아이템 목록
          </Typography>
          <LoadingButton
            variant="outlined"
            onClick={handleExcelDownload}
            loading={isExcelDownload}
            style={{ marginRight: '10px', textTransform: 'none' }}
          >
            엑셀다운로드
          </LoadingButton>
        </Box>
        <TightDataGridPro
          rows={hasSearched && data?.content ? makeRowIndex(data) : []}
          columns={gridColDef()}
          paginationMode="server"
          rowCount={hasSearched ? data?.totalElements ?? 0 : 0}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          onPageChange={page => setQueryParams(params => ({ ...params, page }))}
          pageSize={queryParams.size ?? DEFAULT_SIZE}
          onPageSizeChange={size => setQueryParams(params => ({ ...params, size }))}
          loading={isValidating}
          hasSearched={hasSearched}
        />
      </Stack>
    </Page>
  );
};

export default StocktakingLocationItems;

const styles = {
  divider: {
    gridRow: 4,
    gridColumn: '1/-1',
    pb: 1,
    mb: 1,
  },
};
