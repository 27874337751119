import React, { createContext, ReactNode, useMemo, useState } from 'react';
import Snackbar, { Severity } from 'components/common/Snackbar';
import Alert from './Alert';
import Dialog, { Button } from './Dialog';
import ApiFailure, { ApiFailureProps } from './ApiFailure';
import ConfirmDialog from './ConfirmDialog';

export type ShowAlert = (args: { message: string; onClick?: () => void }) => void;
export type ShowDialog = (args: { message: string; buttons: Button[] }) => void;
export type ShowSnackbar = (args: { message: string; severity: Severity }) => void;
export type ShowErrorDialog = (args: ApiFailureProps & { buttons: Button[] }) => void;
export type ShowConfirmDialog = (args: { message: string }) => Promise<boolean>;

interface PopupActions {
  showAlert: ShowAlert;
  showDialog: ShowDialog;
  showSnackbar: ShowSnackbar;
  showErrorDialog: ShowErrorDialog;
  showConfirmDialog: ShowConfirmDialog;
}

type Props = {
  children: ReactNode;
};
type Popup = 'alert' | 'dialog' | 'error_dialog' | 'confirm_dialog' | null;

export const PopupContext = createContext({} as PopupActions);

const PopupProvider = (props: Props) => {
  const [popupType, setPopupType] = useState<Popup>(null);
  const [alert, setAlert] = useState({
    message: '',
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onClick: () => {},
  });
  const [dialog, setDialog] = useState({
    message: '',
    buttons: [] as Button[],
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info' as Severity,
  });
  const [errorDialog, setErrorDialog] = useState<ApiFailureProps & { buttons: Button[] }>({
    title: '',
    errorMessage: '',
    buttons: [],
  });
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    message: '',
    resolve: null as ((value: boolean) => void) | null,
  });

  const handleClose = () => {
    setPopupType(null);
  };

  const actions: PopupActions = useMemo(
    () => ({
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      showAlert: ({ message, onClick = () => {} }: { message: string; onClick?: () => void }) => {
        setAlert({ message, onClick });
        setPopupType('alert');
      },
      showDialog: ({ message, buttons }: { message: string; buttons: Button[] }) => {
        setDialog({ message, buttons });
        setPopupType('dialog');
      },
      showSnackbar: ({ message, severity }: { message: string; severity: Severity }) => {
        setSnackbar({ open: true, message, severity });
      },
      showErrorDialog: ({
        title,
        top,
        bottom,
        errorCode,
        errorMessage,
        buttons,
      }: ApiFailureProps & { buttons: Button[] }) => {
        setErrorDialog({ title, top, bottom, errorCode, errorMessage, buttons });
        setPopupType('error_dialog');
      },
      showConfirmDialog: ({ message }) => {
        return new Promise<boolean>(resolve => {
          setConfirmDialog({ open: true, message, resolve });
          setPopupType('confirm_dialog');
        });
      },
    }),
    []
  );

  return (
    <PopupContext.Provider value={actions}>
      {props.children}
      <Alert
        open={popupType === 'alert'}
        message={alert.message}
        onClose={handleClose}
        onClick={alert.onClick}
      />

      <Dialog open={popupType === 'dialog'} buttons={dialog.buttons} onClose={handleClose}>
        {dialog.message}
      </Dialog>

      <Snackbar
        open={snackbar.open}
        message={snackbar.message}
        onClose={() => setSnackbar(snackbar => ({ ...snackbar, open: false }))}
        severity={snackbar.severity}
      />

      <Dialog
        open={popupType === 'error_dialog'}
        buttons={errorDialog.buttons}
        onClose={handleClose}
      >
        <ApiFailure
          errorMessage={errorDialog.errorMessage}
          errorCode={errorDialog.errorCode}
          title={errorDialog.title}
          top={errorDialog.top}
          bottom={errorDialog.bottom}
        />
      </Dialog>

      <ConfirmDialog
        open={popupType === 'confirm_dialog'}
        message={confirmDialog.message}
        onConfirm={() => {
          confirmDialog.resolve?.(true);
          handleClose();
        }}
        onCancel={() => {
          confirmDialog.resolve?.(false);
          handleClose();
        }}
      />
    </PopupContext.Provider>
  );
};

export default PopupProvider;
