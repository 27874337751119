import { Fragment } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Barcode from '../../../../components/common/Barcode';
import Ktown4uLogo from '../../../../components/common/Ktown4uLogo';
import Table from '../../components/Table';
import { TableCell, TableRow } from '../../components/Table2';
import { Pickup } from '../on-site/index.page';

const Receipt = ({ pickup }: { pickup: Pickup }) => {
  const sortedItems = pickup.items.sort((a, b) =>
    Number(a.goodsName) - Number(b.goodsName) ? 1 : -1
  );
  const totalQty = sortedItems.reduce((curr, item) => curr + item.qty, 0);

  return (
    <Stack sx={styles.body}>
      <Stack sx={styles.top}>
        <Typography sx={{ fontSize: 24, fontWeight: 'bold' }}>주문내역서</Typography>
        <Box sx={styles.zone}>
          <Typography sx={{ fontSize: 24, fontWeight: 'bold' }}>{pickup.zone}</Typography>
          <Typography sx={{ fontSize: 12, height: 23, ml: 1 }}>zone</Typography>
        </Box>
      </Stack>
      <Table>
        <TableRow>
          <TableCell
            label={'고객ID'}
            sx={styles.itemCell}
            labelSx={styles.label}
            valueSx={styles.itemValue}
          >
            <Typography fontSize={10}>{maskId(pickup.userId)}</Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            label={'고객명'}
            sx={styles.itemCell}
            labelSx={styles.label}
            valueSx={styles.itemValue}
          >
            <Typography fontSize={10}>{maskName(pickup.userName)}</Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            label={'연락처'}
            sx={styles.itemCell}
            labelSx={styles.label}
            valueSx={styles.itemValue}
          >
            <Typography fontSize={10}>{maskPhone(pickup.userPhone)}</Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            label={'수령신청일'}
            sx={styles.itemCell}
            labelSx={styles.label}
            valueSx={styles.itemValue}
          >
            <Typography fontSize={10}>{pickup.requestedPickUpDate}</Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            label={'주문일'}
            sx={{ ...styles.itemCell, borderBottom: '1px solid black' }}
            labelSx={styles.label}
            valueSx={styles.itemValue}
          >
            <Typography fontSize={10}>{pickup.orderDate}</Typography>
          </TableCell>
        </TableRow>
      </Table>
      <Stack alignItems="center" my={2}>
        <Barcode value={pickup.orderSeq} height={60} width={1} />
        <Typography fontSize={12}>{pickup.orderSeq}</Typography>
      </Stack>
      <Box flex={1} width={256}>
        <Typography fontSize={20} fontWeight="bold">
          주문내역
        </Typography>
        <Stack>
          <StyledBoxContainer sx={{ gridTemplateColumns: '1fr 5.8fr 1.2fr' }}>
            <StyledBox sx={{ gridColumn: '1' }}>NO</StyledBox>
            <StyledBox sx={{ gridColumn: '2' }}>상품명</StyledBox>
            <StyledBox sx={{ gridColumn: '3' }}>수량</StyledBox>
            {sortedItems.map((item, index) => (
              <Fragment key={item.goodsId}>
                <StyledBoxCenterAligned sx={{ gridColumn: '1' }}>
                  {index + 1}
                </StyledBoxCenterAligned>
                <StyledBoxLeftAligned sx={{ gridColumn: '2', pl: 1 }}>
                  {item.goodsCode} {item.goodsName}
                </StyledBoxLeftAligned>
                <StyledBoxCenterAligned sx={{ gridColumn: '3' }}>{item.qty}</StyledBoxCenterAligned>
              </Fragment>
            ))}
            <StyledBox sx={{ gridColumn: '1/3', p: 1 }}>수량합계</StyledBox>
            <StyledBoxCenterAligned sx={{ gridColumn: '3' }}>{totalQty}</StyledBoxCenterAligned>
          </StyledBoxContainer>
        </Stack>
      </Box>
      <Box mt={2}>
        <Ktown4uLogo width={100} />
      </Box>
    </Stack>
  );
};

export default Receipt;

const maskId = (id: string) => {
  if (!id) {
    return '';
  }

  if (id.length < 5) {
    return id[0] + new Array(id.length - 1).fill('*').join('');
  }

  const showingId = id.slice(0, 4);
  const indexOfAt = showingId.indexOf('@');

  if (indexOfAt > 0) {
    return id.slice(0, indexOfAt) + new Array(id.length - indexOfAt + 1).fill('*').join('');
  } else {
    return id.slice(0, 4) + new Array(id.length - 4).fill('*').join('');
  }
};

const maskName = (name: string) => {
  if (!name) {
    return '';
  }

  const englishRegex = /[a-zA-Z]/;
  if (englishRegex.test(name)) {
    if (name.length < 5) {
      return name.slice(0, name.length - 1) + '*';
    } else {
      return name.slice(0, 4) + new Array(name.length - 4).fill('*').join('');
    }
  }

  if (name.length < 3) {
    return name[0] + new Array(name.length - 1).fill('*').join('');
  } else {
    return name[0] + new Array(name.length - 2).fill('*').join('') + name[name.length - 1];
  }
};

const maskPhone = (phone: string) => {
  if (!phone) {
    return '';
  }

  if (phone.length < 8) {
    return phone[0] + new Array(phone.length - 1).fill('*').join('');
  }

  return (
    phone.slice(0, 3) +
    new Array(phone.length - 7).fill('*').join('') +
    phone.slice(phone.length - 4, phone.length)
  );
};

const styles = {
  body: {
    width: 260,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    my: 2,
  },
  top: {
    width: 258,
    height: 28,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  zone: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  itemCell: {
    alignItems: 'stretch',
    border: '1px solid black',
    borderBottom: 'none',
    fontSize: 10,
  },
  label: {
    height: 24,
    width: 100,
    justifyContent: 'center',
    backgroundColor: 'white',
    borderRight: '1px solid black',
  },
  itemValue: {
    width: 154,
    height: 24,
    display: 'flex',
    alignItems: 'center',
  },
  stylesForStyledBox: {
    borderRight: `1px solid black`,
    borderBottom: `1px solid black`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 10,
  },
};

const StyledBoxContainer = styled(Box)(() => ({
  display: 'grid',
  borderLeft: `1px solid black`,
  borderTop: `1px solid black`,
  gap: 0,
}));

const StyledBox = styled(Box)(() => ({
  ...styles.stylesForStyledBox,
}));

const StyledBoxCenterAligned = styled(Box)(() => ({
  ...styles.stylesForStyledBox,
  justifyContent: 'center',
}));

const StyledBoxLeftAligned = styled(Box)(() => ({
  ...styles.stylesForStyledBox,
  justifyContent: 'flex-start',
}));
