import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Page from 'components/common/Layout/Page';
import Typography from '@mui/material/Typography';
import pageTitle from '../../../../styles/pageTitle';
import borderedBox from '../../../../styles/borderedBox';
import Timeline from '../../outbound/components/Timeline';
import FullScreenButton from '../../outbound/components/FullScreenButton';
import BarcodeScanLine from './components/BarcodeScanLine';
import CompletePickup from './components/CompletePickup';
import CompleteNotice from './components/CompleteNotice';

const completionStep = 2;

export type PickupItem = {
  id: string;
  goodsName: string;
  goodsId: number;
  goodsCode: string;
  barcode: string;
  offlineBarcode: string;
  imageUrl: string;
  qty: number;
};

export type Pickup = {
  orderNo: number;
  orderSeq: string;
  status: string;
  zone: string;
  requestedPickUpDate: string;
  receivedDate: string;
  orderDate: string;
  userId: string;
  userName: string;
  userPhone: string;
  items: PickupItem[];
};

export const PICKUP_STATUS = {
  ready: 'READY',
  picking: 'PICKING',
  handedOver: 'HANDED_OVER',
  pickupCompleted: 'PICKUP_COMPLETE',
} as const;

const PickupOnSite = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [pickup, setPickup] = useState<Pickup>();

  useEffect(() => {
    return () => {
      setCurrentStep(0);
    };
  }, [setCurrentStep]);

  const changeStep = (step: number) => {
    setCurrentStep(step);
  };

  return (
    <Page>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h2" sx={pageTitle}>
          매장 본인수령 관리
        </Typography>
        <FullScreenButton />
      </Stack>
      <Box sx={styles.timelineContainer}>
        <Timeline currentStep={currentStep} sx={{ padding: 0, margin: 0 }}>
          <BarcodeScanLine pickup={pickup} setPickup={setPickup} changeStep={changeStep} />
          <CompletePickup orderSeq={pickup?.orderSeq} changeStep={changeStep} />
        </Timeline>
        {currentStep === completionStep && pickup && (
          <CompleteNotice orderSeq={pickup.orderSeq} changeStep={changeStep} />
        )}
      </Box>
    </Page>
  );
};

export default PickupOnSite;

const styles = {
  timelineContainer: {
    ...borderedBox,
    flex: 1,
    height: '90vh',
    padding: '32px',
  },
};
