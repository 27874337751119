import { useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import Box from '@mui/material/Box';

const PrintWaybill = ({
  base64Image,
  onCompleted,
}: {
  base64Image: string;
  onCompleted: () => void;
}) => {
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (base64Image) {
      reactToPrintFn();
    }
  }, [base64Image]);

  const reactToPrintFn = useReactToPrint({
    contentRef,
    onAfterPrint: () => {
      onCompleted();
      window.close();
    },
  });

  return (
    <Box ref={contentRef} sx={{ position: 'absolute', alignSelf: 'center', top: 40 }}>
      <img
        src={'data:image/png;base64,' + base64Image}
        alt="Printable"
        style={{ width: 400, height: 600 }}
      />
    </Box>
  );
};

export default PrintWaybill;
