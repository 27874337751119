import React, { useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import _ from 'lodash';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import FormControl from '@mui/material/FormControl';
import Page from '../../../../components/common/Layout/Page';
import SaveButtonGroup from '../../components/SaveButtonGroup';
import FormModal from '../../../../components/common/FormModal';
import Table, { TableCell, TableRow } from '../../components/Table2';
import Filter, { type Form } from '../../../../components/common/Filter';
import TightDataGridPro from '../../../../components/common/TightDataGridPro';
import usePopup from '../../../../hooks/usePopup';
import fetcher from '../../../../libs/common/fetcher';
import refCodeOptionsAtom from '../../../../store/outbound/refCode.recoil';
import { carrierOptionsAtom } from '../../../../store/outbound/carrier.recoil';
import { warehouseAtom, warehouseOptionsAtom } from '../../../../store/outbound/warehouse.recoil';
import { FONT_SIZE } from '../../../../consts/common/typography';
import { CONVEYOR_PATH_ARR } from '../../../../consts/info/conveyorPath';
import { ROWS_PER_PAGE_OPTIONS } from '../../../../consts/common/dataGrid';
import { DEFAULT_PAGE, DEFAULT_SIZE } from '../../../../consts/common/pageAndSize';
import pageTitle from '../../../../styles/pageTitle';
import { listBoardTheme } from '../../../../styles/customedMuiTheme';
import type { Options } from '../../../../types/form';
import SelectBase from '../../info/devices/components/SelectBase';
import useConditionalSWR from '../../components/useConditionalSwr';
import { updateShippingPickingWorkflow } from './services';
import gridColumns from './gridColDef';
import { RANGE } from '../../../../components/common/Filter/DateRangePickerWithSelect/RangeSelect';
import { generateExcelDownload } from './services/excelDownload';
import ExcelUpload from './component/ExcelUpload';
import { reassignAllConveyorPaths } from './services';
import devOnlyFlag from '../../../../libs/common/devOnlyFlag';

type SearchQuery = {
  page?: number;
  size?: number;
  warehouseId?: number;
  shippingStatus?: string;
  pathNumber?: string;
  carrierId?: number;
  salesTypeByShop?: string;
};

type ConveyorPackingWorkflowContent = {
  shippingId: number;
  warehouseId: number;
  shippingStatus: string;
  shippingSerialNumber: string;
  deliveryId: string;
  pathNumber: string;
  sku: number;
  totalQty: number;
  totalWeight: number;
  recommendBox: string;
  carrierId: number;
  isTube: boolean;
  pickingGroupSerialNumber: string;
  shippingGroupSerialNumber: string;
  updatedUsername: string;
  updatedUserLoginId: string;
  updatedAt: string;
};

type ConveyorPackingWorkflow = {
  content: ConveyorPackingWorkflowContent[];
  totalElements: number;
  size: number;
  number: number;
};

const defaultOption = { displayName: '전체', value: 'ALL' };

const searchWordOptions = [
  {
    displayName: '아이템코드',
    field: 'itemCodes',
  },
  {
    displayName: '바코드',
    field: 'barcodes',
  },
  {
    displayName: '배송번호',
    field: 'deliveryIds',
  },
  {
    displayName: '배송ID',
    field: 'deliverySeqs',
  },
  {
    displayName: '출고ID',
    field: 'shippingSerialNumbers',
  },
];

const dateRangeTypeOptions: Options = [
  {
    displayName: '출고지시생성일',
    field: 'shippingOrderCreatedAt',
  },
  {
    displayName: '집품그룹생성일',
    field: 'pickingGroupCreatedAt',
  },
];

const gridTemplateColumns = [
  '60px',
  'minmax(20px, 1fr)',
  '90px',
  'minmax(10px, 0.6fr)',
  'minmax(10px, 0.1fr)',
  'minmax(10px, 0.1fr)',
  'minmax(10px, 0.1fr)',
  'minmax(10px, 0.4fr)',
  '90px',
  'minmax(20px, 1fr)',
  '40px',
  'minmax(20px, 1.2fr)',
].reduce((prevValue, labelWidth) => prevValue + labelWidth + ' ', '');

const ShippingConveyorWorkflows = () => {
  const globalWarehouse = useRecoilValue(warehouseAtom);
  const warehouseOption = useRecoilValue(warehouseOptionsAtom);
  const refCodeOptions = useRecoilValue(refCodeOptionsAtom);
  const shippingStatusOptions = refCodeOptions?.shippingStatus || [];
  const carrierOptions = useRecoilValue(carrierOptionsAtom);

  const multipleStatusSelectRef = useRef<{ initialValues: () => void }>(null);
  const multiplePathSelectRef = useRef<{ initialValues: () => void }>(null);
  const multipleCarrierSelectRef = useRef<{ initialValues: () => void }>(null);

  const [hasSearched, setHasSearched] = useState(false);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [isChangeWorkflowModalOpen, setIsChangeWorkflowModalOpen] = useState(false);
  const [selectedPathNumber, setSelectedPathNumber] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [isExcelDownload, setIsExcelDownload] = useState(false);
  const [isExcelUploadModalOpen, setIsExcelUploadModalOpen] = useState(false);
  const [isReassigningAll, setIsReassigningAll] = useState(false);

  const { showErrorDialog, showSnackbar, showDialog, showConfirmDialog } = usePopup();

  const [queryParams, setQueryParams] = useState<SearchQuery>({
    page: DEFAULT_PAGE,
    size: DEFAULT_SIZE,
    warehouseId: Number(globalWarehouse),
  });

  const { data, mutate, isValidating } = useConditionalSWR<ConveyorPackingWorkflow>(
    [`/conveyors/shippings/search`, { ...queryParams, sort: 'id,desc' }],
    fetcher,
    hasSearched
  );

  const handleInitClick = async () => {
    multipleStatusSelectRef.current?.initialValues();
    multiplePathSelectRef.current?.initialValues();
    multipleCarrierSelectRef.current?.initialValues();

    setQueryParams({
      page: DEFAULT_PAGE,
      size: DEFAULT_SIZE,
      warehouseId: Number(globalWarehouse),
    });
    await mutate();
  };

  const handleSearchClick = async (form: Form) => {
    const updatedForm = _.omitBy(form, o => o === defaultOption.value);
    delete updatedForm[`dateType`];
    assignNullToEmptyNumberRangeField(updatedForm);

    setQueryParams(({ size }) => ({ ...updatedForm, size, page: DEFAULT_PAGE }));
    !hasSearched && setHasSearched(true);
    await mutate();
  };

  const makeRows = (data: ConveyorPackingWorkflow) => {
    if (!data) return [];

    const { content, totalElements, size, number } = data;

    return content.map((row, index) => ({
      ...row,
      rowIndex: totalElements - size * number - index,
      conveyorName: row.pathNumber,
    }));
  };

  const handleOpenChangeWorkflowModal = () => {
    if (selectedRows.length === 0) {
      showSnackbar({ message: '포장경로를 변경할 출고건을 선택해주세요', severity: 'error' });
      return;
    }

    setIsChangeWorkflowModalOpen(true);
  };

  const handleSaveChangedPackingWorkflow = async () => {
    setIsLoading(true);

    const response = await updateShippingPickingWorkflow({
      shippingIds: selectedRows,
      conveyorPath: selectedPathNumber,
    });

    if (response?.status === 200) {
      showSnackbar({
        message: '포장경로 정보가 수정 완료되었습니다',
        severity: 'success',
      });

      await mutate();
      setIsChangeWorkflowModalOpen(false);
      setSelectedRows([]);
    } else {
      showErrorDialog({
        title: '포장경로 정보 수정 실패',
        errorMessage: response?.data?.errorMessage,
        buttons: [{ text: '확인' }],
      });
    }

    setIsLoading(false);
  };

  const handleExcelDownload = async () => {
    setIsExcelDownload(true);
    const response = await generateExcelDownload(queryParams);
    if (response?.status === 200) {
      showSnackbar({ message: '엑셀 다운로드가 완료되었습니다.', severity: 'success' });
    } else {
      showErrorDialog({
        title: '엑셀 다운로드 실패',
        errorMessage: response?.errorMessage,
        buttons: [{ text: '확인' }],
      });
    }
    setIsExcelDownload(false);
  };

  const handleExcelUpload = () => {
    setIsExcelUploadModalOpen(true);
  };

  const handleCloseExcelUploadModal = () => {
    setIsExcelUploadModalOpen(false);
  };

  const handleReassignAll = async () => {
    const confirmed = await showConfirmDialog({
      message: `
      현재 포장경로 조건을 기준으로 자동일괄변경 하시겠습니까?
      기존 배정된 경로는 삭제됩니다.

      (대상 출고건: 출고대기 / 집품그룹생성완료 / 집품)
      `,
    });

    if (!confirmed) return;

    setIsReassigningAll(true);

    const response = await reassignAllConveyorPaths(Number(globalWarehouse));

    if (response?.status === 200) {
      showSnackbar({
        message: '포장경로 자동일괄변경이 완료되었습니다',
        severity: 'success',
      });
      await mutate();
    } else {
      showErrorDialog({
        title: '포장경로 자동일괄변경 실패',
        errorMessage: response?.data?.errorMessage,
        buttons: [{ text: '확인' }],
      });
    }

    setIsReassigningAll(false);
  };

  return (
    <Page>
      <Typography variant="h2" sx={pageTitle}>
        출고건별 포장경로 관리
      </Typography>
      <Filter gridTemplateColumns={gridTemplateColumns}>
        <Filter.Select
          label="창고"
          field="warehouseId"
          options={[defaultOption, ...warehouseOption]}
          labelGridColumn="1/2"
          selectGridColumn="2/3"
          defaultValue={globalWarehouse}
        />
        <Filter.MultipleSelectPlaceholder
          ref={multipleStatusSelectRef}
          label="출고상태"
          field="shippingStatus"
          options={[defaultOption, ...shippingStatusOptions]}
          defaults={['OPEN', 'PREPARING_PICKING', 'PICKING']}
          labelGridColumn="3/4"
          selectGridColumn="4/9"
        />
        <Filter.MultipleSelectPlaceholder
          ref={multiplePathSelectRef}
          label="포장경로"
          field="pathNumber"
          options={[defaultOption, ...CONVEYOR_PATH_ARR]}
          labelGridColumn="9/10"
          selectGridColumn="10/11"
        />
        <Filter.VerticalSearchWithSelect
          label="검색어"
          labelGridRow="1/2"
          labelGridColumn="11/12"
          selectOptions={searchWordOptions}
          selectGridRow="1/2"
          selectGridColumn="12/13"
          textAreaGridRow="2/4"
          textAreaGridColumn="12/13"
          minRows={3}
          maxRows={3}
        />
        <Filter.NumberRangePicker
          label="아이템수량"
          rangeStartField="itemCountFrom"
          rangeEndField="itemCountTo"
          unit=""
          gridRow={2}
          labelGridColumn="1/2"
          rangeInputGridColumn="2/3"
        />
        <Filter.NumberRangePicker
          label="실제중량무게(g)"
          rangeStartField="realWeightFrom"
          rangeEndField="realWeightTo"
          unit=""
          gridRow={2}
          labelGridColumn="3/4"
          rangeInputGridColumn="4/9"
        />
        <Filter.NumberRangePicker
          label="실제부피무게(g)"
          rangeStartField="volumeWeightFrom"
          rangeEndField="volumeWeightTo"
          unit=""
          gridRow={2}
          labelGridColumn="9/10"
          rangeInputGridColumn="10/11"
        />
        <Filter.DateRangePickerWithSelect
          label="기간"
          rangeTypeOptions={dateRangeTypeOptions}
          rangeDefaultValue={RANGE.lastMonth}
          gridRow={3}
          labelGridColumn="1/2"
          selectGridColumn="2/3"
          dateRangePickerGridColumn="3/7"
          rangeAmountSelectGridColumn="7/9"
        />
        <Filter.MultipleSelectPlaceholder
          ref={multipleCarrierSelectRef}
          label="배송방식"
          field="carrierIds"
          gridRow={3}
          options={[defaultOption, ...carrierOptions]}
          labelGridColumn="9/10"
          selectGridColumn="10/11"
        />
        <Divider sx={styles.divider} />
        <Filter.DefaultButtonGroup
          gridColumn="1/-1"
          gridRow={5}
          onInitClick={handleInitClick}
          onLookupClick={handleSearchClick}
          sx={{ display: 'flex', justifyContent: 'center' }}
        />
      </Filter>
      <Stack spacing={1} sx={listBoardTheme.container}>
        <Box sx={listBoardTheme.header}>
          <Box>
            <Typography variant="h6" sx={{ color: 'text.primary' }}>
              출고건별 포장경로 목록
            </Typography>
          </Box>
          <Box>
            <LoadingButton
              variant="outlined"
              onClick={handleReassignAll}
              loading={isReassigningAll}
              style={{ marginRight: '10px', textTransform: 'none' }}
            >
              포장경로 자동일괄변경
            </LoadingButton>
            <Button
              variant="outlined"
              onClick={handleOpenChangeWorkflowModal}
              style={{ marginRight: '2px', textTransform: 'none' }}
            >
              포장경로 변경
            </Button>
            <Button
              sx={listBoardTheme.headerButton}
              variant="outlined"
              suppressHydrationWarning
              onClick={handleExcelUpload}
              style={{ marginRight: '10px', textTransform: 'none' }}
            >
              포장경로 엑셀업로드
            </Button>
            <LoadingButton
              variant="outlined"
              onClick={handleExcelDownload}
              loading={isExcelDownload}
            >
              엑셀다운로드
            </LoadingButton>
          </Box>
        </Box>
        <TightDataGridPro
          rows={hasSearched ? (data?.content ? makeRows(data) : []) : []}
          getRowId={row => row.shippingId}
          columns={gridColumns()}
          paginationMode="server"
          rowCount={hasSearched ? data?.totalElements ?? 0 : 0}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          onPageChange={page => setQueryParams(params => ({ ...params, page }))}
          pageSize={queryParams.size ?? DEFAULT_SIZE}
          onPageSizeChange={size => setQueryParams(params => ({ ...params, size }))}
          checkboxSelection
          disableSelectionOnClick
          selectionModel={selectedRows}
          onSelectionModelChange={ids => setSelectedRows(ids as number[])}
          loading={isValidating}
          hasSearched={hasSearched}
        />
        <FormModal
          open={isChangeWorkflowModalOpen}
          title={'포장경로 변경'}
          onClose={() => setIsChangeWorkflowModalOpen(false)}
        >
          <Box sx={styles.tableBox}>
            <Table>
              <TableRow sx={styles.rowHeight}>
                <TableCell label={'포장경로'} labelSx={styles.label} required>
                  <FormControl fullWidth>
                    <SelectBase
                      name={'conveyorPath'}
                      options={[{ displayName: '선택', value: '' }, ...CONVEYOR_PATH_ARR]}
                      selected={selectedPathNumber}
                      sx={styles.select}
                      onChange={e => setSelectedPathNumber(e.target.value)}
                    />
                  </FormControl>
                </TableCell>
              </TableRow>
            </Table>
            <SaveButtonGroup
              onSaveClick={handleSaveChangedPackingWorkflow}
              onCloseClick={() => setIsChangeWorkflowModalOpen(false)}
              isLoading={isLoading}
            />
          </Box>
        </FormModal>
        <FormModal
          open={isExcelUploadModalOpen}
          title={'포장경로 변경'}
          onClose={handleCloseExcelUploadModal}
        >
          <ExcelUpload onCloseClick={handleCloseExcelUploadModal} onMutate={mutate} />
        </FormModal>
      </Stack>
    </Page>
  );
};

export default ShippingConveyorWorkflows;

const styles = {
  divider: {
    gridRow: 4,
    gridColumn: '1/-1',
    pb: 1,
    mb: 1,
  },
  rowHeight: { height: 36 },
  tableBox: { width: 300 },
  label: { width: 128, fontSize: FONT_SIZE.medium },
  select: { width: 140 },
};

const assignNullToEmptyNumberRangeField = (updatedForm: Form) => {
  if (!updatedForm.itemCountFrom) updatedForm.itemCountFrom = null;
  if (!updatedForm.itemCountTo) updatedForm.itemCountTo = null;
  if (!updatedForm.realWeightFrom) updatedForm.realWeightFrom = null;
  if (!updatedForm.realWeightTo) updatedForm.realWeightTo = null;
  if (!updatedForm.volumeWeightFrom) updatedForm.volumeWeightFrom = null;
  if (!updatedForm.volumeWeightTo) updatedForm.volumeWeightTo = null;
};
