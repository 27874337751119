import pLimit from 'p-limit';
import React, { useRef, useState } from 'react';
import _ from 'lodash';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Page from 'components/common/Layout/Page';
import Filter, { Form } from 'components/common/Filter';
import TightDataGridPro from 'components/common/TightDataGridPro';
import { ROWS_PER_PAGE_OPTIONS } from 'consts/common/dataGrid';
import fetcher from 'libs/common/fetcher';
import pageTitle from 'styles/pageTitle';
import { listBoardTheme } from 'styles/customedMuiTheme';
import type { ShippingInShippingOrder } from 'types/outbound';
import { carrierOptionsAtom, carriersAtom } from '../../../../store/outbound/carrier.recoil';
import gridColDef from './gridColDef';
import { Options } from 'types/form';
import usePopup from 'hooks/usePopup';
import FormModal from 'components/common/FormModal';
import { CreateWaybillRequest, createWaybill, getShippingIdWithoutWaybill } from './services';
import YtoFetchResultModal from './components/YtoFetchResultModal';
import LoadingModal from '../components/LoadingModal';
import TopBoard from '../../components/TopBoard';
import { useRecoilValue } from 'recoil';
import { warehouseAtom, warehouseOptionsAtom } from '../../../../store/outbound/warehouse.recoil';
import { DEFAULT_PAGE, DEFAULT_SIZE } from '../../../../consts/common/pageAndSize';
import { refCodeOptionsAtom } from '../../../../store/outbound/refCode.recoil';
import useConditionalSWR from '../../components/useConditionalSwr';
import LoadingButton from '@mui/lab/LoadingButton';
import { generateExcelDownload } from './services/excelDownload';
import { RANGE } from '../../../../components/common/Filter/DateRangePickerWithSelect/RangeSelect';

type WaybillResult = {
  isSuccess: boolean;
  errorMessage: string;
};

const dateRangeTypeOptions: Options = [
  {
    displayName: '출고지시일',
    field: 'shippingOrderCreatedAt',
  },
  {
    displayName: '출고기준일',
    field: 'shippingBasedAt',
  },
  {
    displayName: '출고완료일',
    field: 'shippingCompletedAt',
  },
];

const searchKeywordOptions: Options = [
  {
    displayName: '출고지시ID',
    field: 'shippingOrderSerialNumber',
  },
  {
    displayName: '집품그룹ID',
    field: 'pickingGroupSerialNumber',
  },
];

const shippingOptions: Options = [
  {
    displayName: '출고ID',
    field: 'shippingSerialNumber',
  },
  {
    displayName: '배송ID',
    field: 'deliverySeq',
  },
  {
    displayName: '배송번호',
    field: 'deliveryId',
  },
  {
    displayName: '송장번호',
    field: 'trackingNumber',
  },
];

const defaultOption = { displayName: '전체', value: 'ALL' };

type Shippings = ShippingInShippingOrder & {
  shippingSerialNumber: string;
  isStock: boolean;
  packingErrorCode: string;
  trackingNumber: string;
};

type ShippingResponse = {
  content: Shippings[];
  totalElements: number;
  size: number;
  number: number;
};

export type SearchQuery = {
  page?: number;
  size?: number;
  warehouseId?: number;
};

type ShippingStatusCount = {
  status: string;
  count: number;
};

const gridTemplateColumns = [
  '36px',
  'minmax(45px, 0.6fr)',
  'minmax(45px, 0.6fr)',
  '50px',
  'minmax(55px, 2fr)',
  'minmax(55px, 1.3fr)',
  '50px',
  'minmax(55px, 1fr)',
  'minmax(55px, 1fr)',
  '50px',
  'minmax(55px, 0.6fr)',
  'minmax(55px, 0.6fr)',
].reduce((prevValue, labelWidth) => prevValue + labelWidth + ' ', '');

type YtoFetchResult = {
  status: number;
  data: {
    total: number;
    successCount: number;
    failCount: number;
    errorMessage?: string;
  };
};

const defaultYtoFetchResult = {
  status: 0,
  data: {
    total: 0,
    successCount: 0,
    failCount: 0,
  },
};

const Shippings = () => {
  const [ytoFetchResult, setYtoFetchResult] = useState<YtoFetchResult>(defaultYtoFetchResult);
  const [isFetchingYtoWaybills, setIsFetchingYtoWaybills] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);
  const [isDownload, setIsDownload] = useState(false);
  const { showDialog, showAlert, showSnackbar, showErrorDialog } = usePopup();
  const globalWarehouse = useRecoilValue(warehouseAtom);
  const warehouseOption = useRecoilValue(warehouseOptionsAtom);
  const [queryParams, setQueryParams] = useState<SearchQuery>({
    page: DEFAULT_PAGE,
    size: DEFAULT_SIZE,
    warehouseId: Number(globalWarehouse),
  });
  const carrierOptions = useRecoilValue(carrierOptionsAtom);
  const multipleSaleTypeSelectRef = useRef<{ initialValues: () => void }>(null);
  const multipleCarrierSelectRef = useRef<{ initialValues: () => void }>(null);
  const refCodeOptions = useRecoilValue(refCodeOptionsAtom);
  const salesTypeByShopOptions = refCodeOptions?.salesTypeByShop || [];
  const rangeSelectRef = useRef<{ selectRange: (value: string) => void }>();

  const { data: shippingStatusCounts, mutate: countMutate } = useConditionalSWR<
    ShippingStatusCount[]
  >([`/shippings/status/counts`, { warehouseId: globalWarehouse }], fetcher, hasSearched);

  const { data, isValidating, mutate } = useConditionalSWR<ShippingResponse>(
    [`/shippings`, { ...queryParams, sort: 'id,desc' }],
    fetcher,
    hasSearched
  );
  const shippingStatusOptions = refCodeOptions?.shippingStatus || [];

  const trackingStatusOptions = refCodeOptions?.trackingStatus || [];
  const topBoard = [
    {
      title: '송장(선)미발행',
      value: 0,
    },
    {
      title: '송장오류',
      value: 0,
    },
    {
      title: 'DHL POP 미발행',
      value: 0,
    },
    {
      title: 'DHL POP 오류',
      value: 0,
    },
  ];

  shippingStatusCounts?.forEach((item: ShippingStatusCount) => {
    if (item.status === 'unissued') {
      topBoard[0].value = item.count;
    } else if (item.status === 'error') {
      topBoard[1].value = item.count;
    }
  });

  const makeRowIndex = (data: ShippingResponse) => {
    const { content, totalElements, size, number } = data;

    return content.map((item: Shippings, index: number) => ({
      ...item,
      rowIndex: totalElements - size * number - index,
    }));
  };

  const handleInitClick = async () => {
    rangeSelectRef.current?.selectRange(RANGE.lastMonth);
    multipleSaleTypeSelectRef.current?.initialValues();
    multipleCarrierSelectRef.current?.initialValues();

    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const previousMonth = date.getMonth().toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    const today = `${year}-${month}-${day}`;
    const oneMonthAgo = `${year}-${previousMonth}-${day}`;

    setQueryParams(() => ({
      page: DEFAULT_PAGE,
      size: DEFAULT_SIZE,
      warehouseId: Number(globalWarehouse),
      shippingOrderCreatedAtFrom: oneMonthAgo,
      shippingOrderCreatedAtTo: today,
    }));

    await countMutate();
  };

  const pageMutate = async () => {
    await mutate();
    await countMutate();
  };
  const handleSearchClick = async (form: Form) => {
    const updatedForm = _.omitBy(form, o => o === defaultOption.value);
    delete updatedForm[`dateType`];

    setQueryParams(({ size }) => ({ ...updatedForm, size, page: DEFAULT_PAGE }));
    !hasSearched && setHasSearched(true);
    await pageMutate();
  };
  const handleFetchYtoWaybills = () => {
    showDialog({
      message: 'YTO 송장을 발행하시겠습니까?',
      buttons: [
        {
          text: '취소',
        },
        {
          text: '확인',
          onClick: async () => {
            setIsFetchingYtoWaybills(true);
            // 송장발행할 출고건 조회
            const shippingIdsResponse = await getShippingIdWithoutWaybill(globalWarehouse);
            const shippingIds = shippingIdsResponse?.data.shippingIds;
            const shippingIdsWithWaybill = shippingIds?.length > 0;
            if (!shippingIdsWithWaybill) {
              showAlert({ message: 'YTO송장을 발행할 출고건이 없습니다' });
              return;
            }

            // 송장발행: 10개씩 동시에 요청
            const concurrency = 20;
            const limit = pLimit(concurrency);
            const createdWaybills: WaybillResult[] = shippingIds?.map((shippingId: number) =>
              limit(() => onCreateWaybill(shippingId))
            );

            // 결과 출력
            const result = await Promise.all(createdWaybills);
            printResult(result);

            setIsFetchingYtoWaybills(false);
            await pageMutate();
          },
        },
      ],
    });
  };

  const onCreateWaybill = async (shippingId: number) => {
    const requestBody: CreateWaybillRequest = {
      warehouseId: globalWarehouse,
      shippingIds: [shippingId],
    };
    type CreateWaybillResponse = {
      isSuccess: boolean;
      deliveryId: number;
      errorMessage: string;
    };
    const response = await createWaybill(requestBody);
    if (response?.status !== 200) {
      return { isSuccess: false, errorMessage: '송장 발행 요청이 실패했습니다.' };
    }
    const createdWaybillResponses = response?.data as CreateWaybillResponse[];
    const result: WaybillResult[] = createdWaybillResponses.map(item => {
      if (item.isSuccess ?? false) {
        return { isSuccess: true, errorMessage: '' };
      } else {
        const errorMessage = `배송번호: ${item.deliveryId} / 실패사유: ${item?.errorMessage}`;
        return { isSuccess: false, errorMessage: errorMessage };
      }
    });
    return result[0];
  };

  const printResult = (result: WaybillResult[]) => {
    const totalCount = result.length;
    const successCount = result.filter(item => item.isSuccess).length;
    const failErrorMessages = result.filter(item => !item.isSuccess).map(item => item.errorMessage);

    console.log(failErrorMessages);

    setYtoFetchResult({
      status: 200,
      data: {
        total: totalCount,
        successCount: successCount,
        failCount: totalCount - successCount,
      },
    });
  };

  const handleClose = () => {
    setYtoFetchResult(defaultYtoFetchResult);
  };

  const handleShippingExcelDownload = async () => {
    setIsDownload(true);
    const response = await generateExcelDownload(queryParams);
    if (response?.status === 200) {
      showSnackbar({ message: '엑셀 다운로드가 완료되었습니다.', severity: 'success' });
      setIsDownload(false);
    } else {
      setIsDownload(false);
      showErrorDialog({
        title: '엑셀 다운로드 실패',
        errorMessage: response?.errorMessage,
        buttons: [{ text: '확인' }],
      });
    }
  };

  return (
    <Page>
      <Typography variant="h2" sx={pageTitle}>
        출고 현황 관리
      </Typography>
      <TopBoard data={topBoard} />
      <Filter gridTemplateColumns={gridTemplateColumns}>
        <Filter.Select
          label="창고"
          field="warehouseId"
          options={[defaultOption, ...warehouseOption]}
          labelGridColumn="1/2"
          selectGridColumn="2/4"
          defaultValue={globalWarehouse}
        />
        <Filter.Select
          label="출고상태"
          field="status"
          options={[defaultOption, ...shippingStatusOptions]}
          labelGridColumn="4/5"
          selectGridColumn="5/10"
        />
        <Filter.MultipleSelectPlaceholder
          ref={multipleSaleTypeSelectRef}
          label="매출구분"
          field="salesTypeByShops"
          options={[defaultOption, ...salesTypeByShopOptions]}
          labelGridColumn="10/11"
          selectGridColumn="11/13"
        />
        <Filter.DateRangePickerWithSelect
          label="기간"
          ref={rangeSelectRef}
          rangeTypeOptions={dateRangeTypeOptions}
          rangeDefaultValue={RANGE.lastMonth}
          gridRow={2}
          typeDefaultValue="shippingOrderCreatedAt"
          labelGridColumn="1/2"
          selectGridColumn="2/4"
          dateRangePickerGridColumn="4/6"
          rangeAmountSelectGridColumn="6/7"
        />
        <Filter.MultipleSelectPlaceholder
          ref={multipleCarrierSelectRef}
          label="배송방식"
          field="carrierIds"
          gridRow={2}
          options={[defaultOption, ...carrierOptions]}
          labelGridColumn="7/8"
          selectGridColumn="8/10"
        />
        <Filter.Select
          label="송장여부"
          field="trackingStatus"
          gridRow={2}
          options={[defaultOption, ...trackingStatusOptions]}
          labelGridColumn="10/11"
          selectGridColumn="11/13"
        />
        <Filter.SearchWithSelect
          label="검색어"
          gridRow={3}
          searchOptions={searchKeywordOptions}
          labelGridColumn="1/2"
          selectGridColumn="2/4"
          inputGridColumn="4/7"
        />
        <Filter.SearchWithSelect
          label="출고건"
          gridRow={3}
          searchOptions={shippingOptions}
          labelGridColumn="7/8"
          selectGridColumn="8/10"
          inputGridColumn="10/13"
        />
        <Divider sx={styles.divider} />
        <Filter.DefaultButtonGroup
          gridColumn="1/-1"
          gridRow={5}
          onInitClick={handleInitClick}
          onLookupClick={handleSearchClick}
          sx={{ display: 'flex', justifyContent: 'center' }}
        />
      </Filter>
      <Stack spacing={1} sx={listBoardTheme.container}>
        <Box sx={listBoardTheme.header}>
          <Typography variant="h6" sx={{ color: 'text.primary' }}>
            출고 목록
          </Typography>
          <Box>
            <LoadingButton
              sx={styles.gridBorderTopButton}
              variant="outlined"
              suppressHydrationWarning
              loading={isDownload}
              onClick={handleShippingExcelDownload}
            >
              {`엑셀다운로드`}
            </LoadingButton>
            <Button
              sx={listBoardTheme.headerButton}
              variant="contained"
              suppressHydrationWarning
              onClick={handleFetchYtoWaybills}
            >
              YTO송장 자동발행
            </Button>
          </Box>
        </Box>
        <TightDataGridPro
          rows={hasSearched && data?.content ? makeRowIndex(data) : []}
          columns={gridColDef()}
          paginationMode="server"
          rowCount={hasSearched ? data?.totalElements ?? 0 : 0}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          onPageChange={page => setQueryParams(params => ({ ...params, page }))}
          pageSize={queryParams.size ?? DEFAULT_SIZE}
          onPageSizeChange={size => setQueryParams(params => ({ ...params, size }))}
          loading={isValidating}
          hasSearched={hasSearched}
        />
      </Stack>
      <FormModal
        open={ytoFetchResult.data.total > 0}
        title="YTO 송장발행 완료"
        onClose={handleClose}
      >
        <YtoFetchResultModal
          total={ytoFetchResult.data.total}
          success={ytoFetchResult.data.successCount}
          error={ytoFetchResult.data.failCount}
          onClose={handleClose}
        />
      </FormModal>
      {isFetchingYtoWaybills && (
        <LoadingModal isLoading={isFetchingYtoWaybills} message={'YTO송장 자동발행중...'} />
      )}
    </Page>
  );
};

export default Shippings;

const styles = {
  divider: {
    gridRow: 4,
    gridColumn: '1/-1',
    pb: 1,
    mb: 1,
  },
  gridBorderTopButton: {
    marginRight: 1,
  },
};
