import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import borderedBox from 'styles/borderedBox';
import { COLORS } from 'consts/common/colors';

type Props = {
  data: { title: string; value: string | number }[];
};

const TopBoard = ({ data }: Props) => {
  return (
    <Box sx={styles.container}>
      {data.map((item, idx) => {
        return (
          <Box key={item.title} sx={{ display: 'flex', flexDirection: 'row' }}>
            <Typography variant="h6" sx={{ mr: 2 }}>
              {item.title}
            </Typography>
            <Typography variant="h6" sx={{ color: COLORS.ktown4u, fontWeight: 'bold' }}>
              {item.value?.toLocaleString()}
            </Typography>
            {data.length - 1 > idx && (
              <Typography variant="h6" sx={{ mx: 2 }}>
                |
              </Typography>
            )}
          </Box>
        );
      })}
    </Box>
  );
};

export default TopBoard;

const styles = {
  container: {
    ...borderedBox,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 60,
    fontSize: 20,
  },
};
