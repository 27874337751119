import { atom } from 'recoil';
import { GmsMenuItem } from '../../components/common/Layout/menus';

export const favoriteMenusAtom = atom<Pick<GmsMenuItem, 'title' | 'route'>[]>({
  key: 'favoriteMenusAtom',
  default: [],
});

const menuAtoms = {
  favoriteMenus: favoriteMenusAtom,
};

export const reloadOutletAtom = atom<number>({
  key: 'reloadOutlet',
  default: 0,
});

export default menuAtoms;
